import React, { useEffect, useState } from "react"
import { Card, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import UpdateTable from "../../../components/Common/UpdatedTable"
import { fetchFinanceJournalEntry } from "../../../services/finance/finance"
import moment from "moment"
import JournalEntryFilter from "./filter"
import { useLocation, useNavigate } from "react-router-dom"
import { capitalize } from "lodash"
import closeIcon from "../../../assets/icons/close.svg"

const JournalEntry = ({ fromDashboard }) => {
  document.title = "Day Book | Ever Rise"

  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const [page, setPage] = useState("")
  const [fetchedData, setFetchedData] = useState()
  const [dateFrom, setDatefrom] = useState("")
  const [dateTo, setDateto] = useState("")
  const [acc, setAcc] = useState("")

  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const location = useLocation()
  useEffect(() => {
    const fetchData = async () => {
      const responce = await fetchFinanceJournalEntry({
        params: `${search ? `&searchText=${search}` : "&searchText"}${
          page ? `&page=${page}` : ""
        }${dateFrom ? `&start_date=${dateFrom}` : ""}${
          dateTo ? `&end_date=${dateTo}` : ""
        }${acc ? `&account_id=${acc}` : ""}${`&sizePerPage=16`}${
          location?.state ? `&ledger_id=${location?.state?.ledger_id}` : ""
        }`,
      })
      setFetchedData(responce)
    }
    fetchData()
  }, [page, search, dateFrom, dateTo, acc, location])

  const handleView = item => {
    setViewData(item)
    toggle()
  }

  const columns = [
    {
      label: "Reference ID",
      renderCell: item => (
        <div
          onClick={() =>
            navigate(`/finance-voucher/view/${item?.voucher_entry?.id}`)
          }
          className="pointer fw-bold"
        >
          {item?.reference_number}
        </div>
      ),
    },
    // {
    //   label: "Account",
    //   renderCell: item => (
    //     <div className="text-capitalize">
    //       {item?.ledger?.account_group_new
    //         ? item?.ledger?.account_group_new?.name
    //         : "-"}
    //     </div>
    //   ),
    // },
    // {
    //   label: "Ledger",
    //   renderCell: item => (
    //     <div className="text-capitalize">
    //       {item?.ledger?.name ? item?.ledger?.name : "-"}
    //     </div>
    //   ),
    // },
    {
      label: "Voucher Type",
      renderCell: item => (
        <div className="text-capitalize">
          {item?.voucher_entry?.voucher_type?.voucher_type
            ? item?.voucher_entry?.voucher_type?.voucher_type
            : "-"}
        </div>
      ),
    },
    {
      label: "Date",
      renderCell: item => moment(item?.created_at).format("DD-MM-YYYY"),
    },
    {
      label: "Description",
      renderCell: item =>
        item?.description ? capitalize(item?.description) : "-",
    },
    {
      label: "Debit (AED)",
      renderCell: item => {
        const totalDebit = item?.journal_entry_details?.reduce(
          (sum, detail) => sum + parseFloat(detail?.debit_amount || 0),
          0
        )
        return (
          <div className="">
            {totalDebit > 0
              ? Number(totalDebit.toFixed(2)).toLocaleString("en", {
                  minimumFractionDigits: 2,
                })
              : 0}
          </div>
        )
      },
    },
    {
      label: "Credit (AED)",
      renderCell: item => {
        const totalCredit = item?.journal_entry_details?.reduce(
          (sum, detail) => sum + parseFloat(detail?.credit_amount || 0),
          0
        )
        return (
          <div className="">
            {totalCredit > 0
              ? Number(totalCredit.toFixed(2)).toLocaleString("en", {
                  minimumFractionDigits: 2,
                })
              : 0}
          </div>
        )
      },
    },
    // {
    //   label: "Debit(AED)",
    //   renderCell: item => (item?.debit_amount ? item?.debit_amount : 0),
    // },
    // {
    //   label: "Credit(AED)",
    //   renderCell: item => (item?.credit_amount ? item?.credit_amount : 0),
    // },
  ]
  const viewColumn = [
    {
      label: "Reference ID",
      renderCell: item => viewData?.reference_number,
    },
    {
      label: "Particulars",
      renderCell: item => (
        <div className="text-capitalize">
          {item?.ledger ? item?.ledger?.name : "-"}
        </div>
      ),
    },
    // {
    //   label: "Ledger",
    //   renderCell: item => (
    //     <div className="text-capitalize">
    //       {item?.ledger?.name ? item?.ledger?.name : "-"}
    //     </div>
    //   ),
    // },
    // {
    //   label: "Voucher Type",
    //   renderCell: item => (
    //     <div className="text-capitalize">
    //       {item?.journal_entry_details[0]?.voucher_type
    //         ? item?.journal_entry_details[0]?.voucher_type
    //         : "-"}
    //     </div>
    //   ),
    // },
    {
      label: "Date",
      renderCell: item => moment(item?.created_at).format("DD-MM-YYYY"),
    },
    {
      label: "Debit(AED)",
      renderCell: item =>
        item?.debit_amount
          ? Number(Number(item?.debit_amount).toFixed(4)).toLocaleString("en", {
              minimumFractionDigits: 2,
            })
          : 0,
    },
    {
      label: "Credit(AED)",
      renderCell: item =>
        item?.credit_amount
          ? Number(Number(item?.credit_amount).toFixed(4)).toLocaleString(
              "en",
              {
                minimumFractionDigits: 2,
              }
            )
          : 0,
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }

  const handleAccChange = data => {
    setAcc(data)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <div>
            {!fromDashboard ? (
              <>
                <Breadcrumbs
                  title="Finance"
                  breadcrumbObject={[
                    { title: "Finance", link: "/finance" },
                    { title: "Day Book", link: "/finance-day-book" },
                  ]}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <Card
            className={`me-2 px-2 pb-2 ${
              fromDashboard ? "dash-table-bg ps-0 pb-0 finance-table-nav" : ""
            }`}
          >
            {!fromDashboard ? (
              <>
                <JournalEntryFilter
                  handleSearchChange={handleSearchChange}
                  handleDateChange={handleDateChange}
                  handleAccChange={handleAccChange}
                  isImport={false}
                />
                <hr className="table-divide" />
              </>
            ) : (
              <div></div>
            )}

            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {fetchedData?.data?.data && (
                  <UpdateTable
                    className="mt-5"
                    nodes={fetchedData?.data?.data}
                    paginate={fetchedData?.data}
                    CurrentPage={page}
                    columns={columns}
                    handlePagination={handlePaginate}
                    tableWidth="15% 15% 10% 20% 20% 20%"
                    mobileWidth="40% 40% 40% 40% 40% 40%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} size="lg" unmountOnClose={false}>
        <>
          <ModalHeader className="custom-modal-header">
            <div className="d-flex justify-content-between">
              <h5 className="ms-2">Details</h5>
              <img
                src={closeIcon}
                alt=""
                onClick={toggle}
                width="10"
                role="button"
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {viewData?.journal_entry_details && (
                  <UpdateTable
                    className="mt-5"
                    nodes={viewData?.journal_entry_details}
                    columns={viewColumn}
                    tableWidth="20% 20% 20% 20% 20%"
                    mobileWidth="40% 40% 40% 40% 40%"
                  />
                )}
              </Col>
            </Row>
          </ModalBody>
        </>
      </Modal>
    </React.Fragment>
  )
}

export default JournalEntry
