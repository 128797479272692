import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  PopoverBody,
  Row,
  TabContent,
  TabPane,
  UncontrolledPopover,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { tradeDocDownload } from "../../../services/procurement/supplier"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
  fetchEmployee,
  fileDownload,
  updateEducationDepartmentStatus,
  updateEmployeeDocumentStatus,
} from "../../../services/Hrms/hrms"
import action from "../../../assets/icons/action.svg"
import dummyImg from "../../../assets/images/dummyImg.svg"
import EditEducation from "./editEducation"
import EditDocument from "./editDocument"
import { toastSuccess } from "../../../helpers/msg_helper"
import EditIncrement from "./editIncrement"
import SalaryHistory from "./salaryHistory"
import { capitalize } from "lodash"
import moment from "moment"
import PayrollHistory from "./payrollHistory"
const ViewEmployee = () => {
  const [employee, setEmployee] = useState()
  const { id } = useParams()
  const [age, setAge] = useState(null)
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("1")
  const [additionPayroll, setAdditionPayroll] = useState([])
  const [deductionPayroll, setDeductionPayroll] = useState([])
  const [totalSalary, setTotalSalary] = useState(0)

  //education modal
  const [educationRefresh, setEducationRefresh] = useState(0)
  const [editEducationFlag, setEditEducationFlag] = useState(false)
  const [editEducation, setEditEducation] = useState({
    modal: false,
    value: "",
    id: "",
  })

  const handleEducationUpdate = () => {
    setEducationRefresh(educationRefresh + 1)
  }

  const handleEducationReset = () => {
    setEditEducation({
      modal: false,
      value: "",
      id: "",
    })
    setEditEducationFlag(false)
  }

  const handleEducationEdit = item => {
    setEditEducationFlag(!editEducationFlag)
    setEditEducation({ modal: true, value: item, id: item?.id })
  }

  const handleEducationUpdateStatus = item => {
    const updateStatus = async item => {
      const response = await updateEducationDepartmentStatus(item)
      toastSuccess(response?.message)
      handleEducationUpdate()
    }
    updateStatus(item)
  }

  //end

  //document modal
  const [documentRefresh, setDocumentRefresh] = useState(0)
  const [documentFlag, setDocumentFlag] = useState(false)
  const [editDocument, setEditDocument] = useState({
    modal: false,
    value: "",
    id: "",
  })

  const handleDocumentUpdate = () => {
    setDocumentRefresh(documentRefresh + 1)
  }

  const handleDocumentReset = () => {
    setEditDocument({
      modal: false,
      value: "",
      id: "",
    })
    setDocumentFlag(false)
  }

  const handleDocumentEdit = item => {
    setDocumentFlag(!documentFlag)
    setEditDocument({ modal: true, value: item, id: item?.id })
  }

  const handleDocumentUpdateStatus = item => {
    const updateStatus = async item => {
      const response = await updateEmployeeDocumentStatus(item)
      toastSuccess(response?.message)
      handleDocumentUpdate()
    }
    updateStatus(item)
  }
  //end

  //increment modal
  const [incrementRefresh, setIncrementRefresh] = useState(0)
  const [editIncrement, setEditIncrement] = useState({
    modal: false,
    value: "",
    id: "",
  })

  const handleIncrementUpdate = () => {
    setIncrementRefresh(incrementRefresh + 1)
  }

  const handleIncrementReset = () => {
    // setEditIncrement({
    //   modal: false,
    //   value: "",
    //   id: "",
    // })
  }
  //end

  // history modal
  const [historyRefresh, setHistoryRefresh] = useState(0)
  const [editHistory, setEditHistory] = useState({
    modal: false,
    value: "",
    id: "",
  })
  const [editPayrollHistory, setEditPayrollHistory] = useState({
    modal: false,
    value: "",
    id: "",
  })

  const handleHistoryUpdate = () => {
    setHistoryRefresh(historyRefresh + 1)
  }

  const handleHistoryReset = () => {
    setEditHistory({
      modal: false,
      value: "",
      id: "",
    })
  }
  const handlePayrollReset = () => {
    setEditPayrollHistory({
      modal: false,
      value: "",
      id: "",
    })
  }
  //end

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  useEffect(() => {
    const fetchData = async id => {
      const response = await fetchEmployee({
        id: id,
        type:
          activeTab == 1
            ? "personal"
            : activeTab == 2
            ? "education"
            : activeTab == 3
            ? "payroll"
            : activeTab == 4
            ? "documents"
            : activeTab == 5
            ? "bank"
            : activeTab == 6
            ? "emergency"
            : activeTab == 7
            ? "medical"
            : activeTab == 8
            ? "benefits"
            : activeTab == 9
            ? "entitlement"
            : activeTab == 10
            ? "loan"
            : activeTab == 11
            ? "otherpayments"
            : "",
      })
      setEmployee(response?.data)
      setAge(calculateAge(response?.data?.personal_details?.date_of_birth))
      setEditIncrement({
        modal: false,
        value: response?.data?.payroll,
        id: "",
      })
    }
    fetchData(id)
  }, [activeTab, educationRefresh, documentRefresh, incrementRefresh])

  useEffect(() => {
    const additionArr = employee?.payroll?.filter(
      item => item?.payrollcomponent?.is_deduction === "0"
    )
    const deductionArr = employee?.payroll?.filter(
      item => item?.payrollcomponent?.is_deduction === "1"
    )
    setAdditionPayroll(additionArr)
    setDeductionPayroll(deductionArr)
    // const additionSum = additionArr?.

    let sumAddition = 0
    // additionArr?.forEach(item => {
    //   let sumPercent =
    //     (Number(item?.total_salary) * Number(item?.percentage)) / 100
    //   sumAddition += Number(sumPercent)
    // })

    additionArr?.forEach(item => {
      let sumPercent = Number(item?.percentage)
      sumAddition += Number(sumPercent)
    })

    let sumDeduction = 0
    // deductionArr?.forEach(item => {
    //   let sumPercentDeduction =
    //     (Number(item?.total_salary) * Number(item?.percentage)) / 100
    //   sumDeduction += Number(sumPercentDeduction)
    // })

    deductionArr?.forEach(item => {
      let sumPercentDeduction = Number(item?.percentage)
      sumDeduction += Number(sumPercentDeduction)
    })

    let totalSalarySum =
      Number(additionArr?.[0]?.total_salary) +
      Number(sumAddition) -
      Number(sumDeduction)

    setTotalSalary(totalSalarySum)
  }, [employee])

  const handleTradeDownload = async id => {
    const responce = await tradeDocDownload(id)
  }

  const handleEducationClick = () => {
    // console.log("handle education click")
  }

  const handleDocumentDownload = ({ path, url, downloadName, extension }) => {
    const handleDownload = async () => {
      const response = await fileDownload({
        url: url,
        path,
        downloadName: downloadName,
        extension: extension,
      })
    }
    handleDownload()
  }
  const getExtension = filePath => {
    const parts = filePath.split(".")
    return parts[1]
  }
  const calculateAge = dob => {
    const birthDate = new Date(dob)
    const today = new Date()
    let age = today.getFullYear() - birthDate.getFullYear()
    const monthDiff = today.getMonth() - birthDate.getMonth()
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--
    }
    return age
  }
  const imgUrl = process.env.REACT_APP_IMG_APIKEY
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            <Breadcrumbs
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                { title: "Employee", link: "/hrms-employee" },
                {
                  title: "View Employee",
                  link: "",
                },
              ]}
            />
          </div>
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card">
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "1" ? "active pointer" : "pointer"
                      }
                      onClick={() => {
                        toggle("1")
                      }}
                    >
                      Personal
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "2" ? "active pointer" : "pointer"
                      }
                      onClick={() => {
                        toggle("2")
                      }}
                    >
                      Education
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "3" ? "active pointer" : "pointer"
                      }
                      onClick={() => {
                        toggle("3")
                      }}
                    >
                      Payroll
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "4" ? "active pointer" : "pointer"
                      }
                      onClick={() => {
                        toggle("4")
                      }}
                    >
                      Documents
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "5" ? "active pointer" : "pointer"
                      }
                      onClick={() => {
                        toggle("5")
                      }}
                    >
                      Bank
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "6" ? "active pointer" : "pointer"
                      }
                      onClick={() => {
                        toggle("6")
                      }}
                    >
                      Emergency Contact
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "7" ? "active pointer" : "pointer"
                      }
                      onClick={() => {
                        toggle("7")
                      }}
                    >
                      Medical
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "8" ? "active pointer" : "pointer"
                      }
                      onClick={() => {
                        toggle("8")
                      }}
                    >
                      Benefits
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "9" ? "active pointer" : "pointer"
                      }
                      onClick={() => {
                        toggle("9")
                      }}
                    >
                      Entitlement
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "10" ? "active pointer" : "pointer"
                      }
                      onClick={() => {
                        toggle("10")
                      }}
                    >
                      Loans
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        activeTab === "11" ? "active pointer" : "pointer"
                      }
                      onClick={() => {
                        toggle("11")
                      }}
                    >
                      Other Payments
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="pt-3">
                  <TabPane tabId="1">
                    <Row>
                      <div className="d-flex justify-content-end pe-5">
                        <Link
                          to={`/hrms-employee/edit/${id}`}
                          className={"px-2 mb-3 btn btn-primary"}
                        >
                          {"Edit "}
                        </Link>
                      </div>
                    </Row>
                    <Row className="px-4 py-5 pt-0">
                      <Col sm={12}>
                        <Row className="pb-4">
                          <Col
                            lg={2}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <img
                              src={
                                employee?.photo
                                  ? `${imgUrl}${employee?.photo}`
                                  : dummyImg
                              }
                              alt="profile image"
                              className="emp-profile"
                            />
                          </Col>
                          <Col lg={10}>
                            <Row>
                              <Col lg={3} md={4} sm={6} className="pt-4">
                                <div className="supplier-td-head">Name</div>
                                <div className="supplier-td-body">
                                  {employee
                                    ? `${capitalize(
                                        employee?.first_name
                                      )} ${capitalize(employee?.last_name)}`
                                    : "-"}
                                </div>
                              </Col>
                              <Col lg={3} md={4} sm={6} className="pt-4">
                                <div className="supplier-td-head">
                                  Emp. Code
                                </div>
                                <div className="supplier-td-body capitalize-letter">
                                  {employee ? employee?.employee_code : "-"}
                                </div>
                              </Col>
                              <Col lg={3} md={4} sm={6} className="pt-4">
                                <div className="supplier-td-head">Grade</div>
                                <div className="supplier-td-body">
                                  {employee?.grade?.grade
                                    ? employee?.grade?.grade
                                    : "-"}
                                </div>
                              </Col>
                              <Col lg={3} md={4} sm={6} className="pt-4">
                                <div className="supplier-td-head">
                                  Group Code
                                </div>
                                <div className="supplier-td-body">
                                  {employee ? "-" : "-"}
                                  {/* {employee ? employee?.grade?.grade : "-"} */}
                                </div>
                              </Col>
                              <Col lg={3} md={4} sm={6} className="pt-4">
                                <div className="supplier-td-head">DOJ</div>
                                <div className="supplier-td-body">
                                  {employee?.personal_details?.date_of_join
                                    ? moment(
                                        employee?.personal_details?.date_of_join
                                      ).format("DD-MM-YYYY")
                                    : "-"}
                                </div>
                              </Col>
                              <Col lg={3} md={4} sm={6} className="pt-4">
                                <div className="supplier-td-head">
                                  Department
                                </div>
                                <div className="supplier-td-body">
                                  {employee?.department?.name
                                    ? employee?.department?.name
                                    : "-"}
                                </div>
                              </Col>

                              <Col lg={3} md={4} sm={6} className="pt-4">
                                <div className="supplier-td-head">
                                  Designation
                                </div>
                                <div className="supplier-td-body">
                                  {employee?.designation?.name
                                    ? employee?.designation?.name
                                    : "-"}
                                </div>
                              </Col>
                              <Col lg={3} md={4} sm={6} className="pt-4">
                                <div className="supplier-td-head">Contract</div>
                                <div className="supplier-td-body">
                                  {employee?.employment_type?.label
                                    ? employee?.employment_type?.label
                                    : "-"}
                                </div>
                              </Col>
                              <Col lg={3} md={4} sm={6} className="pt-4">
                                <div className="supplier-td-head">Email ID</div>
                                <div className="supplier-td-body">
                                  {employee?.official_email_id
                                    ? employee?.official_email_id
                                    : "-"}
                                </div>
                              </Col>
                              <Col lg={3} md={4} sm={6} className="pt-4">
                                <div className="supplier-td-head">
                                  Contact No
                                </div>
                                <div className="supplier-td-body capitalize-letter">
                                  {employee?.official_contact_number
                                    ? "+" + employee?.official_contact_number
                                    : "-"}
                                </div>
                              </Col>
                              <Col lg={3} md={4} sm={6} className="pt-4">
                                <div className="supplier-td-head">
                                  Cost Center
                                </div>
                                <div className="supplier-td-body capitalize-letter">
                                  {employee?.employee_type_id?.label
                                    ? employee?.employee_type_id?.label
                                    : "-"}
                                </div>
                              </Col>
                              <Col lg={3} md={4} sm={6} className="pt-4">
                                <div className="supplier-td-head">Status</div>
                                <div className="supplier-td-body capitalize-letter">
                                  {employee ? (
                                    <div>
                                      <div
                                        className={`font-13 ${
                                          employee?.status === 1
                                            ? "status-active"
                                            : "status-inactive"
                                        }`}
                                      >
                                        {employee?.status === 1
                                          ? "Active"
                                          : "Inactive"}
                                      </div>
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr className="m-0" />
                        <Row>
                          <div className="pt-4 customer-heading">
                            {" "}
                            Personal Details
                          </div>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">
                              Date of Birth
                            </div>
                            <div className="supplier-td-body">
                              {employee?.personal_details?.date_of_birth
                                ? moment(
                                    employee?.personal_details?.date_of_birth
                                  ).format("DD-MM-YYYY")
                                : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">Age</div>
                            <div className="supplier-td-body capitalize-letter">
                              {age ? age : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">Gender</div>
                            <div className="supplier-td-body">
                              {employee?.gender_type?.label
                                ? employee?.gender_type?.label
                                : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">
                              Marital Status
                            </div>
                            <div className="supplier-td-body">
                              {employee?.marital_status_id?.label
                                ? employee?.marital_status_id?.label
                                : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">
                              Languages Known
                            </div>
                            <div className="supplier-td-body">
                              {employee?.personal_details?.languages?.length
                                ? employee?.personal_details?.languages?.map(
                                    (dt, index) => (
                                      <>
                                        <span>{dt?.name}</span>
                                        {index + 1 <
                                          employee?.personal_details?.languages
                                            ?.length && <span>, </span>}
                                      </>
                                    )
                                  )
                                : "-"}
                              {/* {employee
                                ? employee?.personal_details?.languages?.name
                                : "-"} */}
                            </div>
                          </Col>

                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">Blood Group</div>
                            <div className="supplier-td-body">
                              {employee?.blood_group?.label
                                ? employee?.blood_group?.label
                                : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">Religion</div>
                            <div className="supplier-td-body">
                              {employee?.personal_details?.religion_id?.religion
                                ? employee?.personal_details?.religion_id
                                    ?.religion
                                : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">Country</div>
                            <div className="supplier-td-body capitalize-letter">
                              {employee?.personal_details?.country_id?.name
                                ? employee?.personal_details?.country_id?.name
                                : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">State</div>
                            <div className="supplier-td-body capitalize-letter">
                              {employee?.personal_details?.state?.name
                                ? employee?.personal_details?.state?.name
                                : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">
                              Employee Type
                            </div>
                            <div className="supplier-td-body capitalize-letter">
                              {employee?.personal_details?.employee_type_id
                                ?.employee_type
                                ? employee?.personal_details?.employee_type_id
                                    ?.employee_type
                                : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">Sponsored By</div>
                            <div className="supplier-td-body capitalize-letter">
                              {employee?.personal_details?.sponsored_by_id
                                ?.sponsored_by
                                ? employee?.personal_details?.sponsored_by_id
                                    ?.sponsored_by
                                : "-"}
                            </div>
                          </Col>
                          {/* <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">Current Job</div>
                            <div className="supplier-td-body capitalize-letter">
                              {employee ? employee?.current_job_id?.value : "-"}
                            </div>
                          </Col> */}
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">
                              Confimation After
                            </div>
                            <div className="supplier-td-body capitalize-letter">
                              {employee?.confirmation_after?.label
                                ? employee?.confirmation_after?.label
                                : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">
                              Personal Email ID
                            </div>
                            <div className="supplier-td-body capitalize-letter">
                              {employee?.personal_details?.personal_email_id
                                ? employee?.personal_details?.personal_email_id
                                : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">
                              Personal Number
                            </div>
                            <div className="supplier-td-body capitalize-letter">
                              {employee?.personal_details
                                ?.personal_contact_number
                                ? "+" +
                                  employee?.personal_details
                                    ?.personal_contact_number
                                : ""}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">
                              Emergency Number
                            </div>
                            <div className="supplier-td-body capitalize-letter">
                              {employee?.personal_details?.emergency_no
                                ? "+" + employee?.personal_details?.emergency_no
                                : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">Camp Code</div>
                            <div className="supplier-td-body capitalize-letter">
                              {employee?.personal_details?.camp_code_id
                                ?.camp_code
                                ? employee?.personal_details?.camp_code_id
                                    ?.camp_code
                                : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">
                              Current Address
                            </div>
                            <div className="supplier-td-body capitalize-letter">
                              {employee?.personal_details?.current_address
                                ? employee?.personal_details?.current_address
                                : "-"}
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={6} className="pt-4">
                            <div className="supplier-td-head">
                              Permanent Address
                            </div>
                            <div className="supplier-td-body capitalize-letter">
                              {employee?.personal_details?.permanent_address
                                ? employee?.personal_details?.permanent_address
                                : "-"}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row className="px-4 py-5 pt-0">
                      <Col sm={6}>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="employee-table mb-4">
                              <thead>
                                <tr>
                                  <th>Degree</th>
                                  <th>Department</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {employee?.education_details?.map(
                                  (item, index) => (
                                    <tr>
                                      <td>{item?.degree?.degree}</td>
                                      <td>{item?.degree_department?.name}</td>
                                      <td>
                                        <>
                                          <div>
                                            <span
                                              id={`PopoverLegacy-${item?.id}`}
                                            >
                                              <img
                                                src={action}
                                                alt=""
                                                className="pointer"
                                              />
                                            </span>
                                          </div>
                                          <UncontrolledPopover
                                            placement="bottom"
                                            target={`PopoverLegacy-${item?.id}`}
                                            trigger="legacy"
                                          >
                                            <PopoverBody>
                                              <div
                                                className={` table-popup-content`}
                                                onClick={() =>
                                                  handleDocumentDownload({
                                                    id: item?.id,
                                                    path: item?.certificates
                                                      ?.split("/")
                                                      .pop(),
                                                    url: `employee-education-details/download/${item?.id}`,
                                                    downloadName:
                                                      "Educational_Doc",
                                                    extension: getExtension(
                                                      item?.certificates
                                                    ),
                                                  })
                                                }
                                              >
                                                Download
                                              </div>
                                              <div
                                                className={` table-popup-content`}
                                                onClick={() =>
                                                  handleEducationEdit(item)
                                                }
                                              >
                                                Edit
                                              </div>
                                              <div
                                                className={` table-popup-content`}
                                                onClick={() =>
                                                  handleEducationUpdateStatus(
                                                    item?.id
                                                  )
                                                }
                                              >
                                                Delete
                                              </div>
                                            </PopoverBody>
                                          </UncontrolledPopover>
                                        </>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <EditEducation
                                key={"x"}
                                onUpdate={handleEducationUpdate}
                                onReset={handleEducationReset}
                                editValue={editEducation}
                                buttonLabel="Add Education"
                                className="px-2 mb-3 btn btn-secondary"
                              />
                            </Col>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row className="px-4 py-5 pt-0">
                      <Col sm={7}>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="employee-table mb-4">
                              <thead>
                                <tr>
                                  <th>Description</th>
                                  <th>Addition/Deduction</th>
                                  <th>Amount</th>
                                  {/* <th>Percentage</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {employee?.payroll?.map((item, index) => (
                                  <tr key={`payroll_${index}`}>
                                    <td>{item?.payrollcomponent?.name}</td>
                                    <td>
                                      {item?.payrollcomponent?.is_deduction == 0
                                        ? "Addition"
                                        : "Deduction"}
                                    </td>
                                    {/* <td>
                                      {(Number(employee?.total_salary) *
                                        Number(item?.percentage)) /
                                        100}
                                    </td> */}
                                    <td>
                                      {item?.percentage ? item?.percentage : 0}
                                    </td>
                                  </tr>
                                ))}
                                <tr className="py-3">
                                  <td className="supplier-td-body">
                                    Total Salary
                                  </td>
                                  <td className="supplier-td-body">
                                    {Number(totalSalary ? totalSalary : 0)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <Col lg="12" xs={12} md={2} sm={12}>
                              <Row>
                                <Col lg={8}>
                                  <EditIncrement
                                    key={"x"}
                                    onUpdate={handleIncrementUpdate}
                                    onReset={handleIncrementReset}
                                    editValue={editIncrement}
                                    buttonLabel="Add Increment"
                                    className="px-2 mb-3 me-3 btn btn-secondary"
                                  />
                                  <SalaryHistory
                                    key={"x"}
                                    onUpdate={handleHistoryUpdate}
                                    onReset={handleHistoryReset}
                                    editValue={editHistory}
                                    buttonLabel="Salary History"
                                    className="px-2 mb-3 me-3 btn btn-secondary"
                                    empId={employee?.id}
                                  />
                                  <PayrollHistory
                                    key={"x"}
                                    onUpdate={handleHistoryUpdate}
                                    onReset={handlePayrollReset}
                                    editValue={editPayrollHistory}
                                    buttonLabel="Payroll History"
                                    className="px-2 mb-3 btn btn-secondary"
                                    empId={employee?.id}
                                  />
                                </Col>
                                <Col lg={4} className="text-end">
                                  <Link
                                    to={`/hrms-employee/edit/${id}`}
                                    className={"px-2 mb-3 btn btn-primary"}
                                  >
                                    {"Edit "}
                                  </Link>
                                </Col>
                              </Row>
                            </Col>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    <Row className="px-4 py-5 pt-0">
                      <Col sm={7}>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="employee-table mb-4">
                              <thead>
                                <tr>
                                  <th>Document Title</th>
                                  <th>Document No</th>
                                  <th>Expiry Date</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {employee?.employee_documents?.map(
                                  (item, index) => (
                                    <tr>
                                      <td>{item?.document_title?.name}</td>
                                      <td>{item?.document_no}</td>
                                      <td>
                                        {moment(item?.expiry_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td>
                                        <>
                                          <div>
                                            <span
                                              id={`PopoverLegacy-${item?.id}`}
                                            >
                                              <img
                                                src={action}
                                                alt=""
                                                className="pointer"
                                              />
                                            </span>
                                          </div>
                                          <UncontrolledPopover
                                            placement="bottom"
                                            target={`PopoverLegacy-${item?.id}`}
                                            trigger="legacy"
                                          >
                                            <PopoverBody>
                                              <div
                                                className={` table-popup-content`}
                                                onClick={() =>
                                                  handleDocumentDownload({
                                                    id: item?.id,
                                                    path: item?.certificates
                                                      ?.split("/")
                                                      .pop(),
                                                    url: `employee-document-details/download/${item?.id}`,
                                                    downloadName:
                                                      "Employee_Doc",
                                                    extension: getExtension(
                                                      item?.filepath
                                                    ),
                                                  })
                                                }
                                              >
                                                Download
                                              </div>
                                              <div
                                                className={` table-popup-content`}
                                                onClick={() =>
                                                  handleDocumentEdit(item)
                                                }
                                              >
                                                Edit
                                              </div>
                                              <div
                                                className={` table-popup-content`}
                                                onClick={() =>
                                                  handleDocumentUpdateStatus(
                                                    item?.id
                                                  )
                                                }
                                              >
                                                Delete
                                              </div>
                                            </PopoverBody>
                                          </UncontrolledPopover>
                                        </>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <EditDocument
                                key={"x"}
                                onUpdate={handleDocumentUpdate}
                                onReset={handleDocumentReset}
                                editValue={editDocument}
                                buttonLabel="Add Documents"
                                className="px-2 mb-3 btn btn-secondary"
                              />
                              {/* <Button
                                color="secondary"
                                className="addButton mt-2"
                                onClick={() => handleEducationClick()}
                              >
                                Add Education
                              </Button> */}
                            </Col>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="5">
                    <Row className="px-4 py-5 pt-0">
                      <Col sm={7}>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <Col lg={10}>
                              <Row>
                                <Col lg={4} md={6} sm={12} className="pt-4">
                                  <div className="supplier-td-head">
                                    Account Holder Name
                                  </div>
                                  <div className="supplier-td-body">
                                    {employee?.personal_details
                                      ?.account_holder_name
                                      ? `${capitalize(
                                          employee?.personal_details
                                            ?.account_holder_name
                                        )}`
                                      : "-"}
                                  </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} className="pt-4">
                                  <div className="supplier-td-head">
                                    Account Number
                                  </div>
                                  <div className="supplier-td-body capitalize-letter">
                                    {employee?.personal_details?.account_number
                                      ? employee?.personal_details
                                          ?.account_number
                                      : "-"}
                                  </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} className="pt-4">
                                  <div className="supplier-td-head">
                                    Bank Name
                                  </div>
                                  <div className="supplier-td-body">
                                    {employee?.banks?.label
                                      ? employee?.banks?.label
                                      : "-"}
                                  </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} className="pt-4">
                                  <div className="supplier-td-head">
                                    Branch Name
                                  </div>
                                  <div className="supplier-td-body">
                                    {employee?.personal_details?.branch_name
                                      ? employee?.personal_details?.branch_name
                                      : "-"}
                                  </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} className="pt-4">
                                  <div className="supplier-td-head">IBAN</div>
                                  <div className="supplier-td-body uppercase-letter">
                                    {employee?.personal_details?.iban
                                      ? employee?.personal_details?.iban
                                      : "-"}
                                  </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} className="pt-4">
                                  <div className="supplier-td-head">
                                    SWIFT/BIC Code
                                  </div>
                                  <div className="supplier-td-body uppercase-letter">
                                    {employee?.personal_details?.swift_bic_code
                                      ? employee?.personal_details
                                          ?.swift_bic_code
                                      : "-"}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="6">
                    <Row className="px-4 py-5 pt-0">
                      <Col sm={6}>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="employee-table mb-4">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Relationship</th>
                                  <th>Phone</th>
                                  <th>E-mail</th>
                                  <th>Address</th>
                                </tr>
                              </thead>
                              <tbody>
                                {employee?.emergencycontacts?.map(
                                  (item, index) => (
                                    <tr>
                                      <td>{item?.name}</td>
                                      <td>{item?.relationships?.name}</td>
                                      <td>+{item?.phone}</td>
                                      <td>{item?.email}</td>
                                      <td>{item?.address}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            <Col lg={12} className="text-end">
                              <Link
                                to={`/hrms-employee/edit/${id}`}
                                className={"px-2 mb-3 btn btn-primary"}
                              >
                                {"Edit "}
                              </Link>
                            </Col>
                            {/* <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <EditEducation
                                key={"x"}
                                onUpdate={handleEducationUpdate}
                                onReset={handleEducationReset}
                                editValue={editEducation}
                                buttonLabel="Add Education"
                                className="px-2 mb-3 btn btn-secondary"
                              />
                            </Col> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="7">
                    <Row className="px-4 py-5 pt-0">
                      <Col sm={6}>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="employee-table mb-4">
                              <thead>
                                <tr>
                                  <th>Vaccine</th>
                                  <th>Booster</th>
                                  <th>Vaccinated On</th>
                                </tr>
                              </thead>
                              <tbody>
                                {employee?.medical_details?.map(
                                  (item, index) => (
                                    <tr>
                                      <td>
                                        {item?.vaccin?.name
                                          ? `${capitalize(item?.vaccin?.name)}`
                                          : "-"}
                                      </td>
                                      <td>
                                        {item?.booster?.name
                                          ? `${capitalize(item?.booster?.name)}`
                                          : "-"}
                                      </td>
                                      <td>
                                        {item?.date
                                          ? moment(item?.date).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "-"}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            <Col lg={12} className="text-end">
                              <Link
                                to={`/hrms-employee/edit/${id}`}
                                className={"px-2 mb-3 btn btn-primary"}
                              >
                                {"Edit "}
                              </Link>
                            </Col>
                            {/* <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <EditEducation
                                key={"x"}
                                onUpdate={handleEducationUpdate}
                                onReset={handleEducationReset}
                                editValue={editEducation}
                                buttonLabel="Add Education"
                                className="px-2 mb-3 btn btn-secondary"
                              />
                            </Col> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="8">
                    <Row className="px-4 py-5 pt-0">
                      <Col sm={6}>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="employee-table mb-4">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {employee?.benefit?.map((item, index) => (
                                  <tr>
                                    <td>
                                      {item?.name
                                        ? `${capitalize(item?.name)}`
                                        : "-"}
                                    </td>
                                    <td>{item?.price ? item?.price : 0}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <Col lg={12} className="text-end">
                              <Link
                                to={`/hrms-employee/edit/${id}`}
                                className={"px-2 mb-3 btn btn-primary"}
                              >
                                {"Edit "}
                              </Link>
                            </Col>
                            {/* <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <EditEducation
                                key={"x"}
                                onUpdate={handleEducationUpdate}
                                onReset={handleEducationReset}
                                editValue={editEducation}
                                buttonLabel="Add Education"
                                className="px-2 mb-3 btn btn-secondary"
                              />
                            </Col> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="9">
                    <Row className="px-4 py-5 pt-0">
                      <Col sm={6}>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="employee-table mb-4">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {employee?.entitlements?.map((item, index) => (
                                  <tr>
                                    <td>
                                      {item?.name
                                        ? `${capitalize(item?.name)}`
                                        : "-"}
                                    </td>
                                    <td>{item?.price ? item?.price : 0}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <Col lg={12} className="text-end">
                              <Link
                                to={`/hrms-employee/edit/${id}`}
                                className={"px-2 mb-3 btn btn-primary"}
                              >
                                {"Edit "}
                              </Link>
                            </Col>
                            {/* <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <EditEducation
                                key={"x"}
                                onUpdate={handleEducationUpdate}
                                onReset={handleEducationReset}
                                editValue={editEducation}
                                buttonLabel="Add Education"
                                className="px-2 mb-3 btn btn-secondary"
                              />
                            </Col> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="10">
                    <Row className="px-4 py-5 pt-0">
                      <Col sm={9}>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="employee-table mb-4">
                              <thead>
                                <tr>
                                  <th>Transaction Code</th>
                                  <th>Date</th>
                                  {/* <th>Transaction Type</th> */}
                                  <th>Description</th>
                                  {/* <th>Tenure</th> */}
                                  <th>Monthly Pay</th>
                                  {/* <th>Document</th> */}
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {employee?.loan?.map((item, index) => (
                                  <tr>
                                    <td>
                                      {item?.otherpayments?.code
                                        ? `${capitalize(
                                            item?.otherpayments?.code
                                          )}`
                                        : "-"}
                                    </td>
                                    <td>
                                      {moment(item?.date).format("DD-MM-YYYY")}
                                    </td>
                                    <td>{item?.otherpayments?.description}</td>
                                    <td>{item?.otherpayments?.month_amount}</td>
                                    {/* <td></td> */}
                                    <td>
                                      <span
                                        className={`font-13 ${
                                          item?.status == 1
                                            ? "status-active"
                                            : "status-inactive"
                                        }`}
                                      >
                                        {item?.status == 0 ? "Pending" : "Paid"}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {/* <Col
                              lg="12"
                              xs={12}
                              md={2}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <EditEducation
                                key={"x"}
                                onUpdate={handleEducationUpdate}
                                onReset={handleEducationReset}
                                editValue={editEducation}
                                buttonLabel="Add Education"
                                className="px-2 mb-3 btn btn-secondary"
                              />
                            </Col> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="11">
                    <Row className="px-4 py-5 pt-0">
                      <Col sm={9}>
                        <Row className="mb-4">
                          <Col lg={12}>
                            <table className="employee-table mb-4">
                              <thead>
                                <tr>
                                  <th>Code</th>
                                  <th>Transaction Type</th>
                                  <th>Payment Type</th>
                                  <th>Amount</th>
                                  <th>Description</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {employee?.otherpayments?.map((item, index) => (
                                  <tr>
                                    <td>{item?.code}</td>
                                    <td>{item?.transaction_type?.name}</td>
                                    <td>
                                      {item?.transaction_type?.is_deduction != 1
                                        ? "Bonus"
                                        : item?.is_loan == 0
                                        ? "Instant Deduction"
                                        : "Loan"}
                                    </td>
                                    <td>{item?.amount} AED</td>
                                    <td>{item?.description}</td>
                                    <td>
                                      <div>
                                        <span
                                          className={`font-13 ${
                                            item?.is_paid == 1
                                              ? "status-active"
                                              : "status-inactive"
                                          }`}
                                        >
                                          {item?.is_paid == 1
                                            ? "Paid"
                                            : "Pending"}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
              {editEducationFlag && (
                <EditEducation
                  modelOpen={editEducationFlag}
                  editValue={editEducation}
                  onUpdate={handleEducationUpdate}
                  onReset={handleEducationReset}
                />
              )}
              {documentFlag && (
                <EditDocument
                  modelOpen={documentFlag}
                  editValue={editDocument}
                  onUpdate={handleDocumentUpdate}
                  onReset={handleDocumentReset}
                />
              )}
            </Card>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewEmployee
