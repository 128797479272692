import Procurement from "../../pages/Procurement"
import PurchaseOrder from "../../pages/Procurement/purchaseOrder"
import EditPurchaseOrder from "../../pages/Procurement/purchaseOrder/edit"
import Supplier from "../../pages/Procurement/supplier"
import EditSupplier from "../../pages/Procurement/supplier/edit"
import ViewSupplier from "../../pages/Procurement/supplier/view"
import MaterialRequest from "../../pages/Procurement/materialrequest"
import EditMaterialRequest from "../../pages/Procurement/materialrequest/edit"
import ViewMaterialRequest from "../../pages/Procurement/materialrequest/view"
import ViewPurchaseOrder from "../../pages/Procurement/purchaseOrder/view"
import Grn from "../../pages/Procurement/grn"
import EditGrn from "../../pages/Procurement/grn/edit"
import ViewMGrn from "../../pages/Procurement/grn/view"
import ManpowerPO from "../../pages/Procurement/manpowerPO"
import CompanyPurchaseOrder from "../../pages/Procurement/companyPO"
import PurchaseOrderVoucherSearch from "../../pages/Procurement/purchaseOrderVoucher"
import StockSummary from "../../pages/Procurement/stockSummary"
import RequestForQuote from "../../pages/Procurement/rfq"
import EditRfq from "../../pages/Procurement/rfq/edit"
import ViewRFQ from "../../pages/Procurement/rfq/view"
import ViewPurchaseOrderVoucher from "../../pages/Procurement/purchaseOrderVoucher/view"
export const procurementRoutes = [
  { path: "/procurement", component: <Procurement /> },
  { path: "/procurement-supplier", component: <Supplier /> },
  { path: "/procurement-supplier/add", component: <EditSupplier /> },
  { path: "/procurement-supplier/edit/:id", component: <EditSupplier /> },
  { path: "/procurement-supplier/view/:id", component: <ViewSupplier /> },
  { path: "/procurement-purchase-order", component: <PurchaseOrder /> },
  { path: "/procurement-purchase-order/add", component: <EditPurchaseOrder /> },
  {
    path: "/procurement-purchase-order/edit/:editId",
    component: <EditPurchaseOrder />,
  },
  {
    path: "/procurement-purchase-order/view/:id",
    component: <ViewPurchaseOrder />,
  },
  {
    path: "/procurement-company-purchase-order/view/:id",
    component: <ViewPurchaseOrder />,
  },
  {
    path: "/procurement-company-purchase-order",
    component: <CompanyPurchaseOrder />,
  },
  {
    path: "/procurement-purchase-order/:action",
    component: <EditPurchaseOrder />,
  },
  { path: "/procurement-materiareq", component: <MaterialRequest /> },
  { path: "/procurement-materiareq/add", component: <EditMaterialRequest /> },
  {
    path: "/procurement-materiareq/edit/:id",
    component: <EditMaterialRequest />,
  },
  {
    path: "/procurement-materiareq/view/:id",
    component: <ViewMaterialRequest />,
  },

  { path: "/procurement-grn", component: <Grn /> },
  { path: "/procurement-grn/add", component: <EditGrn /> },
  { path: "/procurement-grn/add/:editId", component: <EditGrn /> },
  { path: "/procurement-grn/edit/:editId", component: <EditGrn /> },
  { path: "/procurement-grn/view/:id", component: <ViewMGrn /> },
  { path: "/procurment-manpower-puechase-order", component: <ManpowerPO /> },
  {
    path: "/procurment-purchaseorder",
    component: <PurchaseOrderVoucherSearch />,
  },
  {
    path: "/procurment-purchaseorder/view/:id",
    component: <ViewPurchaseOrderVoucher />,
  },
  { path: "/procurment-stocksummary", component: <StockSummary /> },
  { path: "/procurment-rfq", component: <RequestForQuote /> },
  { path: "/procurment-rfq/add", component: <EditRfq /> },
  { path: "/procurment-rfq/view/:id", component: <ViewRFQ /> },
]
