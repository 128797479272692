import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  FormGroup,
  FormFeedback,
  ModalFooter,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { Link, useNavigate } from "react-router-dom"
import {
  searchMaterialRequest,
  searchMaterialRequestFinance,
  searchRfq,
  updateAction,
  updateRfqAction,
  updateRfqReference,
  updateSupplierStatus,
} from "../../../services/procurement/materialrequest"
import moment from "moment"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import RfqFilter from "./filter"
import closeIcon from "../../../assets/icons/close.svg"
import { FormProvider, useForm } from "react-hook-form"
import { UFInput } from "../../../components/Common/Fields/Input"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import LoadingButton from "../../../components/Common/LoadingButton"
import check from "../../../assets/icons/estimation_check.svg"

const RequestForQuote = ({ fromDashboard, projectId }) => {
  document.title = "Request For Quote | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const schema = yup.object().shape({})

  const { loggedUser } = useSelector(LoginProperties)

  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(0)
  const [materialRequest, setMaterialRequest] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [mrno, setMRNo] = useState("")
  const [page, setPage] = useState("")
  const [datefrom, setDatefrom] = useState("")
  const [dateto, setDateto] = useState("")
  const [financial, setFinancial] = useState(true)
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  // const handleActivate = item => {
  //   const updateStatus = async item => {
  //     const response = await updateSupplierStatus(item)
  //     toastSuccess(response?.message)
  //     setRefresh(refresh + 1)
  //   }
  //   updateStatus(item)
  // }

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })
  const [isLoading, setIsLoading] = useState(false)

  const handleAction = async data => {
    const response = await updateRfqAction(data)
    toastSuccess(response?.message)
    setRefresh(refresh + 1)
    // setRefresh1(refresh1 + 1)
  }

  const handleReference = item => {
    setModal(!modal)
    reset({
      id: item?.val?.id,
      ref_code: item?.val?.ref_code ? item?.val?.ref_code : "",
    })
  }

  const columns = [
    {
      label: "RFQ No",
      renderCell: item => {
        return (
          <>
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "materialrequest_view"
            ) ? (
              <div
                onClick={() =>
                  navigate(`/procurment-rfq/view/${item?.id}`)
                }
                className="pointer fw-bold"
              >
                <span className="pe-2">{item?.code}</span>
                {item?.status == 2
                 && <img src={check} className="est-approve-img" />}
              </div>
            ) : (
              <div>{item?.code}</div>
            )}
          </>
        )
      },
    },
    {
      label: "Supplier Name",
      renderCell: item => item?.vendor?.name,
    },
    {
      label: "Quote Date",
      renderCell: item => moment(item?.created_at).format("DD-MM-YYYY"),
    },
    {
      label: "Quote Reference",
      renderCell: item => (item?.ref_code ? item?.ref_code : "-"),
    },
    {
      label: "Action",
      renderCell: item =>
        fromDashboard ? (
          item?.status == 1 ? (
            <>
              <div>
                <Button
                  color={"primary"}
                  className="btn btn-primary me-2"
                  onClick={() =>
                    handleAction({ materialReq: item?.id, status: 2 })
                  }
                >
                  Approve
                </Button>
                {/* <Button
                  className="hover-button"
                  onClick={() =>
                    handleAction({ materialReq: item?.id, status: 2 })
                  }
                >
                  Reject
                </Button> */}
              </div>
            </>
          ) : (
            <></>
          )
        ) : (
          <>
            <div>
              <span id={`PopoverLegacy-${item?.id}`}>
                <img src={action} alt="" className="pointer" />
              </span>
            </div>
            <UncontrolledPopover
              placement="bottom"
              target={`PopoverLegacy-${item?.id}`}
              trigger="legacy"
            >
              <PopoverBody>
                {item?.status == 1 ? (
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      handleAction({ materialReq: item?.id, status: 2 })
                    }
                  >
                    Approve
                  </div>
                ) : (
                  <></>
                )}
                {item?.status == 2 && (
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      navigate(`/finance-voucher/add`, {
                        state: item,
                      })
                    }
                  >
                    Convert to PO
                  </div>
                )}
                {/* {item?.status == 1 && ( */}
                <div
                  className="table-popup-content"
                  onClick={() => handleReference({ val: item })}
                >
                  Update Reference
                </div>
                {/* )} */}
                {/* <div
                className={`table-popup-content`}
                // onClick={() => handleActivate(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div> */}
              </PopoverBody>
            </UncontrolledPopover>
          </>
        ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }
  const handleDateChange = (fromdate, todate) => {
    setDatefrom(fromdate)
    setDateto(todate)
    setPage(1)
  }
  useEffect(() => {
    const fetchMaterialRequest = async () => {
      const response = await searchRfq({
        params: `${
          status == "0" || status == "1" || status == "2"
            ? `&status=${status}`
            : ""
        }${search ? `&searchText=${search}` : "&searchText"}${
          mrno ? `&mr_no=${mrno}` : ""
        }${datefrom ? `&requestdate_from=${datefrom}` : ""}${
          dateto ? `&requestdate_to=${dateto}` : ""
        }${
          page ? `&page=${page}` : ""
        }${`&financial=${financial}`}${`&sizePerPage=16`}${
          fromDashboard ? `&filter_status=0` : ""
        }${fromDashboard && projectId ? `&project_id=${projectId}` : ""}`,
      })
      setMaterialRequest(response)
    }
    fetchMaterialRequest()
  }, [refresh, status, search, mrno, page, datefrom, dateto, financial])
  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleRequestChange = id => {
    setMRNo(id)
    setPage(1)
  }

  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }

  const handleRegistration = async data => {
    try {
      setIsLoading(true)
      const responce = await updateRfqReference(data)
      toastSuccess(responce?.message)
      toggle()
      setRefresh(refresh + 1)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div
        className={`${
          fromDashboard ? "pt-0 dashboard-page-content-view" : "page-content"
        }`}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            {!fromDashboard ? (
              <Breadcrumbs
                title="Procurement"
                breadcrumbObject={[
                  { title: "Procurement", link: "/procurement" },
                  {
                    title: "Request For Quote",
                    link: "/procurment-rfq",
                  },
                ]}
              />
            ) : (
              <></>
            )}
            {loggedUser?.permission?.some(
              permissionItem =>
                permissionItem?.name === "materialrequest_create"
            ) && (
              <Link
                to="/procurment-rfq/add"
                className={"px-2 mb-3 btn btn-primary"}
              >
                {"+ Add"}
              </Link>
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <RfqFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleRequestChange={handleRequestChange}
              handleDateChange={handleDateChange}
              handleFinacialChange={handleFinacialChange}
              sampleUrl={"download/suppliers.xlsx"}
              importUrl={"suppliers/import"}
              isImport={false}
              fromDashboard={fromDashboard}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {materialRequest && (
                  <UpdateTable
                    className="mt-5"
                    nodes={materialRequest?.data?.data}
                    paginate={materialRequest?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="20% 20% 20% 20% 20% "
                    mobileWidth="40% 40% 30% 30% 30% "
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} backdrop={"static"}>
        <ModalHeader className="custom-modal-header">
          <div className="d-flex justify-content-between">
            <h5>Update Reference</h5>{" "}
            <img
              src={closeIcon}
              alt=""
              onClick={toggle}
              width="10"
              role="button"
            />
          </div>
        </ModalHeader>
        <ModalBody>
          <FormProvider {...methods}>
            <Form id={`ev-form`} onSubmit={handleSubmit(handleRegistration)}>
              <Row>
                <Col sm={12}>
                  <FormGroup className="mb-4">
                    <Label>
                      Quote reference no
                      <span className="mandatory">*</span>
                    </Label>
                    <UFInput
                      control={control}
                      placeholder="Enter Quote reference no"
                      name={"ref_code"}
                      invalid={!!errors.ref_code}
                    />
                    <FormFeedback invalid>
                      {errors.ref_code?.message}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            form={`ev-form`}
            color={"primary"}
            loading={isLoading}
            disabled={isLoading}
            type="submit"
          >
            Update{" "}
          </LoadingButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default RequestForQuote
