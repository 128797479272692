import { get, post } from "../../helpers/api_helper"

export const getNotification = data => {
  return get(`notifications/search?${data?.params}`)
}

export const getDocumentNotification = data => {
  return get(`notifications-doc/search?${data?.params}`)
}

// notifications/mark-as-read

export const readAllNotification = () => {
  post(`notifications/mark-as-read`, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
