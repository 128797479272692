import React, { useEffect, useState } from "react"
import { Card, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import {
  fetchFinanceCategory,
  updateCatStatus,
} from "../../../services/finance/finance"
import { capitalize } from "lodash"
import { getNotification } from "../../../services/notification/notification"
import NotificationFilter from "../filter"
import moment from "moment"

const NotificationList = () => {
  document.title = "Notification | Everise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const [page, setPage] = useState("")

  const [series, setSeries] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [count, setCount] = useState(0)
  const [editSeries, setEditSeries] = useState({ modal: false, value: "" })

  useEffect(() => {
    const fetchData = async () => {
      const response = await getNotification({
        params: `${"sizePerPage=16"}${page ? `&page=${page}` : ""}`,
      })
      setSeries(response)
    }
    fetchData()
  }, [count, status, search, page])

  const handleEdit = item => {
    setEditSeries({ value: item, modal: true })
  }

  const handleStatus = async id => {
    try {
      const response = await updateCatStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Title",
      renderCell: item => (item?.title ? capitalize(item?.title) : "-"),
    },
    {
      label: "Message",
      renderCell: item => (item?.message ? capitalize(item?.message) : "-"),
    },
    {
      label: "Date",
      renderCell: item =>
        item?.created_at ? moment(item?.created_at).fromNow() : "-",
    },
    // {
    //   label: "Action",
    //   renderCell: item => (
    //     <>
    //       <div>
    //         <span id={`PopoverLegacy-${item?.id}`}>
    //           <img src={action} alt="" className="pointer" />
    //         </span>
    //       </div>
    //       <UncontrolledPopover
    //         placement="bottom"
    //         target={`PopoverLegacy-${item?.id}`}
    //         trigger="legacy"
    //       >
    //         <PopoverBody>
    //           {item?.status === 1 &&
    //             loggedUser?.permission?.some(
    //               permissionItem =>
    //                 permissionItem?.name === "financecategory_edit"
    //             ) && (
    //               <div
    //                 className="table-popup-content"
    //                 onClick={() => handleEdit(item)}
    //               >
    //                 Edit
    //               </div>
    //             )}
    //         </PopoverBody>
    //       </UncontrolledPopover>
    //     </>
    //   ),
    // },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleUpdate = () => {
    setCount(count + 1)
  }

  const handleResetValue = () => {
    setEditSeries("")
  }

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Notification"
              breadcrumbObject={[
                { title: "Notification", link: "/notification" },
                // { title: "Finance Category", link: "/finance-category" },
              ]}
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <NotificationFilter
              //   handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              showExport={false}
              //   sampleUrl={"download/series.xlsx"}
              //   importUrl={"finance-cat/import"}
              //      ={
              //     loggedUser?.permission?.some(
              //       permissionItem =>
              //         permissionItem?.name === "financecategory_create"
              //     )
              //       ? true
              //       : false
              //   }
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {series?.data && (
                  <UpdateTable
                    className="mt-5"
                    nodes={series?.data?.data}
                    paginate={series?.data}
                    CurrentPage={page}
                    columns={columns}
                    handlePagination={handlePaginate}
                    tableWidth="40% 30% 30%"
                    mobileWidth="40% 40% 40%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NotificationList
