import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { UFDate } from "../../../components/Common/Fields/Input"
import moment from "moment"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { fetchFinanceProfitLoss } from "../../../services/finance/finance"
import UpdateTable from "../../../components/Common/UpdatedTable"
import arrow from "../../../assets/icons/arrow.svg"
import { useNavigate } from "react-router-dom"

const ProfitLossAc = ({ fromDashboard }) => {
  document.title = "Profit & Loss A/C | Ever Rise"

  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  )
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
  const [fetchedData, setFetchedData] = useState()
  const [fetchedTableData, setFetchedTableData] = useState()
  const [debitTotal, setDebitTotal] = useState(0)
  const [creditTotal, setCreditTotal] = useState(0)
  console.log(`fetchedTableData`, fetchedTableData)

  const { control, watch, reset } = useForm({
    defaultValues: {
      req_date: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    },
  })
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      const responce = await fetchFinanceProfitLoss({
        params: `${startDate ? `&start_date=${startDate}` : ""}${
          endDate ? `&end_date=${endDate}` : ""
        }`,
      })
      setFetchedData(responce)

      const tableDatas = {
        directExpense: responce?.data?.Direct_Expenses?.flatMap(item => {
          // Check if the item has a 'ledgers' array
          if (item.ledgers) {
            return item.ledgers.map(ledger => ({
              groupName: item.group_name,
              ledgerName: ledger.ledger_name,
              ledgerBalance: ledger.balance,
            }))
          } else {
            // For items without 'ledgers', include only group_name and balance
            return {
              ledgerName: item.group_name,
              groupName: item.group_name,
              ledgerBalance: item.balance,
            }
          }
        }),
        directIncome: responce?.data?.Direct_Income?.flatMap(item => {
          // Check if the item has a 'ledgers' array
          if (item.ledgers) {
            return item.ledgers.map(ledger => ({
              groupName: item.group_name,
              ledgerName: ledger.ledger_name,
              ledgerBalance: ledger.balance,
            }))
          } else {
            // For items without 'ledgers', include only group_name and balance
            return {
              ledgerName: item.group_name,
              groupName: item.group_name,
              ledgerBalance: item.balance,
            }
          }
        }),
        inDirectIncome: responce?.data?.Indirect_Income?.flatMap(item => {
          // Check if the item has a 'ledgers' array
          if (item.ledgers) {
            return item.ledgers.map(ledger => ({
              groupName: item.group_name,
              ledgerName: ledger.ledger_name,
              ledgerBalance: ledger.balance,
            }))
          } else {
            // For items without 'ledgers', include only group_name and balance
            return {
              ledgerName: item.group_name,
              groupName: item.group_name,
              ledgerBalance: item.balance,
            }
          }
        }),
        inDirectExpense: responce?.data?.Indirect_Expenses?.flatMap(item => {
          // Check if the item has a 'ledgers' array
          if (item.ledgers) {
            return item.ledgers.map(ledger => ({
              groupName: item.group_name,
              ledgerName: ledger.ledger_name,
              ledgerBalance: ledger.balance,
            }))
          } else {
            // For items without 'ledgers', include only group_name and balance
            return {
              ledgerName: item.group_name,
              groupName: item.group_name,
              ledgerBalance: item.balance,
            }
          }
        }),
      }
      setFetchedTableData(tableDatas)
    }
    if (startDate && endDate) {
      fetchData()
    }
  }, [startDate, endDate])

  const handleRequestdate = data => {
    let fromDate = ""
    let toDate = ""
    fromDate = !isEmpty(data) ? moment(data[0]).format("YYYY-MM-DD") : ""
    toDate = !isEmpty(data) ? moment(data[1]).format("YYYY-MM-DD") : ""
    setStartDate(fromDate)
    setEndDate(toDate)
  }

  const columns = [
    {
      label: "",
      renderCell: item => (item?.ledgerName ? item?.ledgerName : "-"),
    },
    {
      label: "",
      renderCell: item => (
        <div className="text-end pe-3">
          {item?.ledgerBalance
            ? Number(Number(item?.ledgerBalance).toFixed(2)).toLocaleString(
                "en",
                { minimumFractionDigits: 2 }
              )
            : "0.00"}
        </div>
      ),
    },
  ]

  const incomeColumn = [
    {
      label: "Group Name",
      renderCell: item => (
        <span className="fw-500 fs-14 ps-2">{item?.group_name}</span>
      ),
    },
    {
      label: "",
      renderCell: item => (
        <div className="fw-500 fs-14">
          {Number(
            Number(item?.balance ? item?.balance : 0).toFixed(2)
          ).toLocaleString("en", { minimumFractionDigits: 2 })}{" "}
          AED
        </div>
      ),
    },
    // {
    //   label: "",
    //   renderCell: item => (
    //     <div className="fw-500 fs-14">
    //       {Number(
    //         Number(item?.credit ? item?.credit : 0).toFixed(2)
    //       ).toLocaleString("en", { minimumFractionDigits: 2 })}{" "}
    //       AED
    //     </div>
    //   ),
    // },
  ]
  const assetSubColumns = [
    {
      label: "Group Name",
      renderCell: item => (
        <div
          onClick={() =>
            navigate(`/finance-ledger-acc`, {
              state: item,
            })
          }
          className="pointer fw-bold fs-13 ps-2"
        >
          {item?.ledger_name}
          <span className="ps-1">
            <img src={arrow} alt="" width="7" />
          </span>
        </div>
      ),
    },
    {
      label: "Amount",
      label: "Amount",
      renderCell: item => (
        <div className="fs-14">
          {Number(Number(item?.balance).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
          })}
          AED
        </div>
      ),
    },
    {
      label: "Amount",
      renderCell: item => (
        <div className="fs-14">
          {Number(Number(item?.credit).toFixed(2)).toLocaleString("en", {
            minimumFractionDigits: 2,
          })}{" "}
          AED
        </div>
      ),
    },
  ]

  const reduceBalance = (assets, amount) => {
    // Reduce the balance of each asset and sum them up
    const total = assets.reduce((sum, asset) => {
      return sum + (asset.balance - amount) // Reduce the balance and accumulate
    }, 0)

    return total // Return the total balance after reduction
  }

  const calculateTotals = assets => {
    const totalDebit = assets.reduce((sum, asset) => sum + asset.debit, 0)
    const totalCredit = assets.reduce((sum, asset) => sum + asset.credit, 0)

    return {
      totalDebit,
      totalCredit,
    }
  }

  const getTotalValue = (data, key) => {
    console.log(`data:`, data)

    return data.reduce((total, item) => {
      return total + (item?.[key] || 0) // Add the specified key's value, defaulting to 0 if undefined
    }, 0)
  }

  useEffect(() => {
    if (fetchedData) {
      const totalDebit = Object.keys(fetchedData?.data).reduce((sum, key) => {
        return sum + getTotalValue(fetchedData?.data[key], "debit")
      }, 0)

      const totalCredit = Object.keys(fetchedData?.data).reduce((sum, key) => {
        return sum + getTotalValue(fetchedData?.data[key], "credit")
      }, 0)

      setDebitTotal(totalDebit)
      setCreditTotal(totalCredit)

      console.log(`totalDebit:`, totalDebit)
      console.log(`totalCredit:`, totalCredit)
    }
  }, [fetchedData])

  // const calculateTotalBalance = dataArray =>
  //   dataArray.reduce((total, item) => total + (item?.ledgerBalance || 0), 0)

  const calculateTotalBalance = dataArray => {
    if (!Array.isArray(dataArray)) return 0 // Return 0 if dataArray is not a valid array
    return dataArray?.reduce(
      (total, item) => total + (item?.ledgerBalance || 0),
      0
    )
  }

  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard ? "pt-0" : ""}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <div>
              {!fromDashboard ? (
                <>
                  <Breadcrumbs
                    title="Finance"
                    breadcrumbObject={[
                      { title: "Finance", link: "/finance" },
                      {
                        title: "Profit & Loss A/C",
                        link: "/finance-profitloss",
                      },
                    ]}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="pe-2">
              <UFDate
                control={control}
                name={"req_date"}
                placeholder={"Date"}
                mode={"range"}
                onDateChange={e => handleRequestdate(e)}
              />
            </div>
          </div>
          <Card
            className={`me-2 px-2 pb-2 ${
              fromDashboard ? "dash-table-bg ps-0 pb-0 finance-table-nav" : ""
            }`}
          >
            <div className="d-flex justify-content-center">
              <div className="purchase-view-div-report">
                <table className="estimation-table">
                  <thead>
                    <tr>
                      <th className="w-50 text-medium ">Particulars</th>
                      <th className="w-50 text-medium  text-end pe-3 ">
                        Amount (AED)
                      </th>
                    </tr>
                  </thead>
                </table>
                {fetchedTableData && (
                  <>
                    <div className="profitloss-head d-flex justify-content-center mb-2">
                      Direct Income
                    </div>
                    <Row className="mt-2 ever-rise-table moduleTable profitTable">
                      <Col xs={12}>
                        <UpdateTable
                          className="mt-5"
                          nodes={fetchedTableData?.directIncome}
                          columns={columns}
                          tableWidth="50% 50%"
                          mobileWidth="50% 50%"
                          headerHide={true}
                        />
                      </Col>
                    </Row>
                    <table className="estimation-table profit-table">
                      <tbody>
                        <tr>
                          <td className="w-50 text-medium ps-2">
                            Total Direct Income
                          </td>
                          <td className="w-50 text-end pe-3 text-medium ">
                            {fetchedData
                              ? Number(
                                  Number(
                                    calculateTotalBalance(
                                      fetchedTableData?.directIncome
                                    )
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="profitloss-head d-flex justify-content-center my-2">
                      Direct Expense
                    </div>
                    <Row className="mt-2 ever-rise-table moduleTable profitTable">
                      <Col xs={12}>
                        <UpdateTable
                          className="mt-5"
                          nodes={fetchedTableData?.directExpense}
                          columns={columns}
                          headerHide={true}
                          tableWidth="50% 50%"
                          mobileWidth="50% 50%"
                        />
                      </Col>
                    </Row>
                    <table className="estimation-table profit-table">
                      <tbody>
                        <tr>
                          <td className="w-50 text-medium ps-2">
                            Total Direct Expense
                          </td>
                          <td className="w-50 text-medium text-end pe-3">
                            {fetchedData
                              ? Number(
                                  Number(
                                    calculateTotalBalance(
                                      fetchedTableData?.directExpense
                                    )
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="estimation-table profit-table my-4">
                      <tbody>
                        <tr>
                          <td className="w-50 text-medium text-end ps-2">
                            Gross{" "}
                            {calculateTotalBalance(
                              fetchedTableData?.directIncome
                            ) -
                              calculateTotalBalance(
                                fetchedTableData?.directExpense
                              ) >=
                            0
                              ? "Profit"
                              : "Loss"}
                          </td>
                          <td className="w-50 text-medium text-end pe-3">
                            {fetchedData
                              ? Number(
                                  Math.abs(
                                    Number(
                                      calculateTotalBalance(
                                        fetchedTableData?.directIncome
                                      ) -
                                        calculateTotalBalance(
                                          fetchedTableData?.directExpense
                                        )
                                    ).toFixed(2)
                                  )
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="profitloss-head d-flex justify-content-center my-2">
                      Indirect Income
                    </div>
                    <Row className="mt-2 ever-rise-table moduleTable profitTable">
                      <Col xs={12}>
                        <UpdateTable
                          className="mt-5"
                          nodes={fetchedTableData?.inDirectIncome}
                          columns={columns}
                          headerHide={true}
                          tableWidth="50% 50%"
                          mobileWidth="50% 50%"
                        />
                      </Col>
                    </Row>
                    <table className="estimation-table profit-table">
                      <tbody>
                        <tr>
                          <td className="w-50 text-medium ps-2">
                            Total Indirect Income
                          </td>
                          <td className="w-50 text-medium text-end pe-3">
                            {fetchedTableData
                              ? Number(
                                  Number(
                                    calculateTotalBalance(
                                      fetchedTableData?.inDirectIncome
                                    )
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="profitloss-head d-flex justify-content-center my-2">
                      Indirect Expense
                    </div>
                    <Row className="mt-2 ever-rise-table moduleTable profitTable">
                      <Col xs={12}>
                        <UpdateTable
                          className="mt-5"
                          nodes={fetchedTableData?.inDirectExpense}
                          columns={columns}
                          headerHide={true}
                          tableWidth="50% 50%"
                          mobileWidth="50% 50%"
                        />
                      </Col>
                    </Row>
                    <table className="estimation-table profit-table">
                      <tbody>
                        <tr>
                          <td className="w-50 text-medium ps-2">
                            Total Indirect Expense
                          </td>
                          <td className="w-50 text-medium text-end pe-3">
                            {fetchedTableData
                              ? Number(
                                  Number(
                                    calculateTotalBalance(
                                      fetchedTableData?.inDirectExpense
                                    )
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="estimation-table profit-table my-4">
                      <tbody>
                        <tr>
                          <td className="w-50 text-medium text-end ps-2">
                            Net{" "}
                            {calculateTotalBalance(
                              fetchedTableData.directIncome || []
                            ) -
                              calculateTotalBalance(
                                fetchedTableData.directExpense || []
                              ) +
                              calculateTotalBalance(
                                fetchedTableData.inDirectIncome || []
                              ) -
                              calculateTotalBalance(
                                fetchedTableData.inDirectExpense || []
                              ) >=
                            0
                              ? "Profit"
                              : "Loss"}
                          </td>
                          <td className="w-50 text-medium text-end pe-3">
                            {fetchedData
                              ? Number(
                                  Math.abs(
                                    calculateTotalBalance(
                                      fetchedTableData.directIncome || []
                                    ) -
                                      calculateTotalBalance(
                                        fetchedTableData.directExpense || []
                                      ) +
                                      calculateTotalBalance(
                                        fetchedTableData.inDirectIncome || []
                                      ) -
                                      calculateTotalBalance(
                                        fetchedTableData.inDirectExpense || []
                                      )
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>

            {/* <div className="d-flex justify-content-between px-3 py-2">
              <div>
                <div className="fw-500">Opening Stock</div>
                <div>
                  {Number(
                    Number(
                      fetchedData?.opening_stock
                        ? fetchedData?.opening_stock
                        : 0
                    ).toFixed(2)
                  ).toLocaleString("en", { minimumFractionDigits: 2 })}{" "}
                  AED
                </div>
              </div>
              <div>
                <div className="fw-500">Closing Stock</div>
                <div>
                  {Number(
                    Number(
                      fetchedData?.closing_stock
                        ? fetchedData?.closing_stock
                        : 0
                    ).toFixed(2)
                  ).toLocaleString("en", { minimumFractionDigits: 2 })}{" "}
                  AED
                </div>
              </div>
            </div>
            <Row className="profit-loss-tbl-hgt">
              <Col lg={12} className="mb-2">

                <table className="estimation-table">
                  <thead>
                    <tr>
                      <th className="w-65">Account Name</th>
                      <th className="w-30">Amount</th>
                    </tr>
                  </thead>
                </table>
                <Row className="mt-2 profitTable">
                  <Col xs={12}>
                    {fetchedData?.data?.expense && (
                      <UpdateTable
                        nodes={fetchedData?.data?.expense}
                        columns={incomeColumn}
                        subColumn={assetSubColumns}
                        subName={"ledgers"}
                        subHeader={false}
                        tableWidth="70% 30%"
                        mobileWidth="70% 30%"
                        expand={true}
                        headerHide={true}
                      />
                    )}
                    <div>
                      <table className="estimation-table trial-balance-total ever-transparent-table mb-2">
                        <thead>
                          <tr>
                            <th className="w-65">Gross Profit C/D</th>
                            <th className="w-30">
                              {fetchedData?.gross_profit_cd
                                ? `${Number(
                                    Number(
                                      fetchedData?.gross_profit_cd
                                    ).toFixed(2)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                  })} AED`
                                : "0.00 AED"}
                            </th>
                          </tr>
                        </thead>
                      </table>
                      <table className="estimation-table trial-balance-total">
                        <thead>
                          <tr>
                            <th className="w-50"></th>
                            <th className="w-25 ps-2">
                              {fetchedData?.data?.expense
                                ? `${Number(
                                    Number(
                                      calculateTotals(
                                        fetchedData?.data?.expense
                                      ).totalDebit
                                    ).toFixed(2)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                  })} AED`
                                : "0.00 AED"}
                            </th>
                            <th className="w-25">
                              {fetchedData?.data?.expense
                                ? `${Number(
                                    Number(
                                      calculateTotals(
                                        fetchedData?.data?.expense
                                      ).totalCredit
                                    ).toFixed(2)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                  })} AED`
                                : "0.00 AED"}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} className="mb-2">
                
                <table className="estimation-table">
                  <thead>
                    <tr>
                      <th className="w-65">Account Name</th>
                      <th className="w-30">Amount</th>
                    </tr>
                  </thead>
                </table>
                <Row className="mt-2 profitTable">
                  <Col xs={12}>
                    {fetchedData?.data?.income && (
                      <>
                        <UpdateTable
                          nodes={fetchedData?.data?.income}
                          columns={incomeColumn}
                          subColumn={assetSubColumns}
                          subName={"ledgers"}
                          subHeader={false}
                          tableWidth="70% 30%"
                          mobileWidth="70% 30%"
                          expand={true}
                          headerHide={true}
                        />
                      </>
                    )}
                    {fetchedData?.gross_profit_bd && (
                      <table className="estimation-table trial-balance-total ever-transparent-table mb-2">
                        <thead>
                          <tr>
                            <th className="w-65">Gross Profit B/D</th>
                            <th className="w-30">
                              {fetchedData?.gross_profit_bd
                                ? `${Number(
                                    Number(
                                      fetchedData?.gross_profit_bd
                                    ).toFixed(2)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                  })} AED`
                                : "0.00 AED"}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    )}
                    <table className="estimation-table trial-balance-total">
                      <thead>
                        <tr>
                          <th className="w-50"></th>
                          <th className="w-25">
                            {fetchedData?.data?.income
                              ? `${Number(
                                  Number(
                                    calculateTotals(fetchedData?.data?.income)
                                      .totalDebit
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })} AED`
                              : "0.00 AED"}
                          </th>
                          <th className="w-25">
                            {fetchedData?.data?.income
                              ? `${Number(
                                  Number(
                                    calculateTotals(fetchedData?.data?.income)
                                      .totalCredit
                                  ).toFixed(2)
                                ).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                })} AED`
                              : "0.00 AED"}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </Col>
                </Row>
              </Col>
            </Row>
            <table className="estimation-table trial-balance-total">
              <thead>
                <tr>
                  <th className="w-50">Net Profit/Loss</th>

                  <th className="w-50">
                    {fetchedData?.net_profit_or_loss
                      ? `${Number(
                          Number(fetchedData?.net_profit_or_loss).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                        })} AED`
                      : 0}
                  </th>
                </tr>
              </thead>
            </table> */}
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProfitLossAc
