import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Controller } from "react-hook-form"
import { getProductStockLi } from "../../../services/Settings/dropdown"

function ucfirst(str) {
  var firstLetter = str.substr(0, 1)
  return firstLetter.toUpperCase() + str.substr(1)
}

const StockProductList = ({
  control,
  name,
  onSelectChange,
  target,
  placeholder,
  selectedProduct,
  index,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [fetchResponse, setFetchResponse] = useState([])

  const loadOptions = async () => {
    const data = await getProductStockLi()
    setIsLoading(false)
    const response = data?.data?.map(dta => {
      return {
        label: dta?.product_id && ucfirst(dta.product_id?.product_code),
        value: dta.id,
        ...dta,
      }
    })
    //   const addProductExists = response.some(
    //     (item) => item.name === "Add New"
    // );

    // if (!addProductExists) {
    //     response.unshift({
    //         value: "Add New",
    //         label: "Add New",
    //     });
    // }
    if (index == 0) {
      setFetchResponse(response)
    } else {
      const usedProduct = selectedProduct?.map(
        dt => dt?.product_id?.value != "" && dt?.product_id?.value
      )
      setFetchResponse(() => {
        const unUsedProduct = response.filter(item1 => {
          return !usedProduct?.some(item2 => item2 === item1.value)
        })
        return unUsedProduct
      })
    }
    // setFetchResponse(response)
  }
  useEffect(() => {
    loadOptions()
  }, [index])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref, value } }) => {
          return (
            <Select
              options={fetchResponse}
              placeholder={placeholder ? placeholder : "Select"}
              isLoading={isLoading}
              inputRef={ref}
              onChange={e => {
                onChange(e)
                if (onSelectChange) onSelectChange(e)
              }}
              value={value}
              classNamePrefix="react-select"
              className="elevate-dropdown"
              menuPortalTarget={document.querySelector(
                target ? target : ".modal"
              )}
            />
          )
        }}
      />
    </div>
  )
}

export default StockProductList
