import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Col,
  Row,
  Card,
  UncontrolledPopover,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  FormFeedback,
  Form,
  Tooltip,
} from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import {
  searchInventoryProduct,
  updateProductStatus,
} from "../../../services/Inventory/inventory"
// import EditTimeSheet from "./edit"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import closeIcon from "../../../assets/icons/close.svg"
import { UFInput, UFMonthYear } from "../../../components/Common/Fields/Input"
// import ProductFilter from "./filter"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import moment from "moment"
import present from "../../../assets/icons/hrms-present.png"
import halfDay from "../../../assets/icons/hrms-halfday.png"
import absent from "../../../assets/icons/hrms-absent.png"
import weeekend from "../../../assets/icons/hrms-weekend.png"
import ProjectNo from "../../../components/Common/chooseFields/projectno"
import Department from "../../../components/Common/chooseFields/department"
import LoadingButton from "../../../components/Common/LoadingButton"
import LeaveType from "../../../components/Common/chooseFields/leaveType"
import {
  postHrmsGeneratePayroll,
  postHrmsTimeSheet,
  searchHrmsTimeSheet,
} from "../../../services/Hrms/hrms"
import CustomPopover from "../../../components/Common/popover"
import PageNumLi from "../../../components/Common/chooseFields/pageNumLi"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const schema = yup.object().shape({
  project_id: yup.mixed().required("Project ID is Required"),
  department_id: yup.mixed().required("Department ID is Required"),
})

const TimeSheet = () => {
  document.title = "Time Sheet | Ever Rise"

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      project_id: null,
      department_id: null,
      // year_month: new Date(),
    },
  })
  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))
  const { loggedUser } = useSelector(LoginProperties)

  const todayDate = moment(loggedUser?.settings?.hrms_att_date).format(
    "YYYY-MM"
  )
  const daysInMonth = moment(loggedUser?.settings?.hrms_att_date).daysInMonth()
  const [leaveCheck, setLeaveCheck] = useState(false)
  const [weekend, setWeekend] = useState([])
  const [halfDayCheck, setHalfDayCheck] = useState(false)
  const [otCheck, setOtCheck] = useState(false)
  const [isLoading1, setIsLoading1] = useState(false)
  const [isLoading2, setIsLoading2] = useState(false)
  const [viewData, setViewData] = useState()
  const [modal, setModal] = useState(false)
  const [classData, setClassData] = useState()
  const [fullTimeData, setFullTimeData] = useState()
  const [refresh, setRefresh] = useState(0)
  const [isGenerate, setIsGenerate] = useState(false)
  const [page, setPage] = useState("")
  const [classPage, setClassPage] = useState()

  const schema2 = yup.object().shape({
    ...(otCheck && {
      hours: yup
        .string()
        .matches(/^([+]?)?[0-9]+$|^$/, "OT hours must be only digits")
        .test(
          "is-less-than-24",
          "OT hours must be less than 24 hours",
          value => {
            if (!value) return true
            const hours = parseInt(Number(value), 10)
            return hours <= 24
          }
        )
        .required("OT hours is required"),
    }),
    ...(leaveCheck && {
      leave_id: yup.mixed().required("Leave Type is required"),
    }),
  })

  // form 1
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  const handleRegistration = async data => {
    try {
      setIsLoading1(true)
      const response = await searchHrmsTimeSheet({
        params: `${
          data?.department_id
            ? `department_id=${data?.department_id?.value}`
            : ""
        }${data?.project_id ? `project_id=${data?.project_id?.value}` : ""}`,
      })
      setWeekend(response?.data?.fridaysInMonth)
      setClassData(response?.data?.data)
      setClassPage(response?.data)
      setFullTimeData(response?.data?.data)
      toastSuccess(response?.message)
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading1(false)
    }
  }
  const handlePaginate = page => {
    setPage(page)
  }
  useEffect(() => {
    const fetchData = async () => {
      const data = getValues()
      const response = await searchHrmsTimeSheet({
        params: `${`year_month=${loggedUser?.settings?.hrms_att_date}`}${
          data?.department_id
            ? `&department_id=${data?.department_id?.value}`
            : ""
        }${page ? `&page=${watch("employee_name") ? 1 : page}` : ""}${
          watch("employee_name") ? `&searchText=${watch("employee_name")}` : ""
        }${
          watch("view")
            ? `&sizePerPage=${watch("view")?.value}`
            : `&sizePerPage=16`
        }`,
      })
      setWeekend(response?.fridaysInMonth)
      setClassData(response?.data?.data)
      setClassPage(response?.data)
      setFullTimeData(response?.data?.data)
      toastSuccess(response?.message)
    }
    if (loggedUser?.settings?.hrms_att_date) {
      fetchData()
    }
  }, [refresh, loggedUser, page, watch("employee_name"), watch("view")])
  // form 2
  const {
    control: controlForm2,
    handleSubmit: handleSubmitForm2,
    reset: resetForm2,
    setValue: setValueForm2,
    formState: { errors: errorsForm2 },
  } = useForm({
    resolver: yupResolver(schema2),
    defaultValues: {
      leave_id: null,
      hours: "",
    },
  })

  const methodsForm2 = useForm({
    resolver: yupResolver(schema2),
    mode: "onChange",
    shouldFocusError: true,
  })

  const handleRegistrationEdit = async data => {
    if (isGenerate) {
      const uniqueEmployeeCheck = [...new Set(employeeCheck)]
      const commonIds = uniqueEmployeeCheck?.filter(id =>
        filteredId?.includes(id)
      )
      try {
        setIsLoading2(true)
        const payrollGenerate = await postHrmsGeneratePayroll({
          date: `${moment(todayDate).format("YYYY-MM")}-01`,
          employee_ids: commonIds,
        })
        toastSuccess(payrollGenerate?.message)
        setModal(false)
        setRefresh(refresh + 1)
        setIsGenerate(false)
        setEmployeeCheck([])
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading2(false)
      }
    } else {
      // if (otCheck || leaveCheck) {
      try {
        setIsLoading2(true)
        const updateDepartment = await postHrmsTimeSheet({
          ...data,
          date: `${moment(todayDate).format("YYYY-MM")}-${viewData?.date}`,
          employee_id: viewData?.id,
          hours: data?.hours,
          leave_id: data?.leave_id?.value,
          is_leave: leaveCheck ? 1 : 0,
          ...(otCheck && { is_ot: otCheck }),
          ...(halfDayCheck && { is_halfday: halfDayCheck }),
        })
        toastSuccess(updateDepartment?.message)
        setModal(false)
        setRefresh(refresh + 1)
      } catch (err) {
        toastError(err?.message)
      } finally {
        setIsLoading2(false)
      }
      // } else {
      //   toastError("Please select either OT or Leave")
      // }
    }
  }

  const toggle = () => {
    setModal(!modal)
    if (!modal == false) {
      setIsGenerate(false)
    }
  }

  const handleModelClick = (item, day) => {
    const viewDetails = { ...item, date: day }
    const loopDate = day.toString().padStart(2, "0")

    let absentValue = item?.absent?.length
      ? item?.absent?.filter(
          dt => dt?.date == `${moment(todayDate).format("YYYY-MM")}-${loopDate}`
        )
      : false
    let overTimeValue = item?.overtime?.length
      ? item?.overtime?.filter(
          dt => dt?.date == `${moment(todayDate).format("YYYY-MM")}-${loopDate}`
        )
      : false
    if (item?.absent?.length && absentValue?.length) {
      setValueForm2("leave_id", {
        label: absentValue?.[0]?.leave?.name,
        value: absentValue?.[0]?.leave?.id,
      })
      setLeaveCheck(true)
      if (absentValue?.[0]?.status == 0) {
        setHalfDayCheck(true)
      }
    }
    if (item?.overtime?.length && overTimeValue?.length) {
      setOtCheck(true)
      setValueForm2("hours", overTimeValue?.[0]?.hours)
    }
    setViewData(viewDetails)
    toggle()
  }

  const dynamicHeader = []
  for (let i = 1; i <= daysInMonth; i++) {
    dynamicHeader.push(i)
  }

  const [tooltipOpen, setTooltipOpen] = useState({})

  const tooltiptoggle = id => {
    setTooltipOpen({ ...tooltipOpen, [id]: !tooltipOpen[id] })
  }
  const [employeeCheck, setEmployeeCheck] = useState([])
  const [filteredId, setFilteredId] = useState([])

  const handleEmployeeCheck = id => {
    if (employeeCheck.includes(id)) {
      const updatedEmployeeCheck = employeeCheck.filter(item => item !== id)
      setEmployeeCheck(updatedEmployeeCheck)
      setFilteredId(updatedEmployeeCheck)
    } else {
      setEmployeeCheck(prevEmployeeCheck => [...prevEmployeeCheck, id])
      setFilteredId(prevEmployeeCheck => [...prevEmployeeCheck, id])
    }
  }
  const handleFullEmployeeCheck = dt => {
    // const idsNot = dt?.filter(item => item?.is_payrollgenerated_count == 0)
    const idsToAdd = dt.map(item => item.id)
    const idsNot = dt
      ?.filter(item => item?.is_payrollgenerated_count == 0)
      .map(item => item?.id)
    const commonIds = idsToAdd?.filter(id => idsNot?.includes(id))

    if (idsToAdd.every(id => employeeCheck.includes(id))) {
      setEmployeeCheck([])
      setFilteredId([])
    } else {
      setEmployeeCheck(prevEmployeeCheck => [...prevEmployeeCheck, ...idsToAdd])
      setFilteredId(commonIds)
    }
  }
  const columns = [
    {
      headerCell: item => (
        <div lg={"auto"} onClick={() => handleFullEmployeeCheck(item)}>
          <input
            type="checkbox"
            className="ever-rise-checkbox"
            name={"is_material"}
            checked={item.every(item => employeeCheck.includes(item.id))}
          />
        </div>
      ),
      checkedHead: true,
      renderCell: item => {
        return (
          <div lg={"auto"} onClick={() => handleEmployeeCheck(item?.id)}>
            <>
              <input
                type="checkbox"
                className="ever-rise-checkbox"
                name={"is_material"}
                checked={employeeCheck?.includes(item?.id)}
                disabled={item?.is_payrollgenerated_count != 0 ? true : false}
              />
            </>
          </div>
        )
      },
    },
    {
      label: "Name",
      renderCell: item => {
        return <div className="capitalize-letter">{item?.first_name}</div>
      },
    },
    ...dynamicHeader.map(day => ({
      label: day.toString(),
      renderCell: item => {
        const loopDate = day.toString().padStart(2, "0")
        let absentValue = item?.absent?.length
          ? item?.absent?.filter(
              dt =>
                dt?.date ===
                `${moment(todayDate).format("YYYY-MM")}-${loopDate}`
            )
          : false
        let weekendValue = weekend?.length
          ? weekend?.some(
              dt => dt === `${moment(todayDate).format("YYYY-MM")}-${loopDate}`
            )
          : false
        let overTimeValue = item?.overtime?.length
          ? item?.overtime?.filter(
              dt =>
                dt?.date == `${moment(todayDate).format("YYYY-MM")}-${loopDate}`
            )
          : false
        let assignedProjects = item?.projects?.length
          ? item?.projects?.filter(
              dt =>
                moment(dt?.pivot?.date).format("YYYY-MM-DD") ==
                `${moment(todayDate).format("YYYY-MM")}-${loopDate}`
            )
          : false
        const tooltipId = "Tooltip-" + item?.id + loopDate

        return (
          <div key={tooltipId}>
            <div onClick={() => handleModelClick(item, day)}>
              <span id={tooltipId} style={{ cursor: "pointer" }}>
                {weekendValue ? (
                  <img src={weeekend} alt="" className="pointer" />
                ) : absentValue?.length && absentValue?.[0]?.status === 1 ? (
                  <img src={absent} alt="" className="pointer" />
                ) : absentValue?.length && absentValue?.[0]?.status === 0 ? (
                  <img src={halfDay} alt="" className="pointer" />
                ) : (
                  <img src={present} alt="" className="pointer" />
                )}
              </span>
            </div>
            {overTimeValue?.length || assignedProjects?.length ? (
              <Tooltip
                placement="bottom"
                isOpen={tooltipOpen[tooltipId]}
                target={tooltipId}
                toggle={() => tooltiptoggle(tooltipId)}
              >
                {overTimeValue?.length
                  ? `${overTimeValue?.[0]?.user?.first_name} worked ${overTimeValue?.[0]?.hours} hours overtime`
                  : ""}
                {overTimeValue?.length && assignedProjects?.length && "/"}
                {assignedProjects?.length
                  ? `Assigned Projects- ${assignedProjects?.map(
                      dt => dt?.project_code
                    )}`
                  : ""}
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        )
      },
    })),
  ]

  const handleLeaveCheck = dt => {
    setLeaveCheck(!dt)
    if (!dt == false) {
      setValueForm2("leave_id", null)
    }
  }
  const handleHalfDayCheck = dt => {
    setHalfDayCheck(!dt)
  }
  const handleOtCheck = dt => {
    setOtCheck(!dt)
    if (!dt == false) {
      setValueForm2("hours", "")
    }
  }

  useEffect(() => {
    if (!modal) {
      resetForm2()
      setLeaveCheck(false)
      setHalfDayCheck(false)
      setOtCheck(false)
      setViewData()
    }
  }, [modal])

  // useEffect(() => {
  //   if (watch("employee_name")) {
  //     const employeeName = watch("employee_name").toLowerCase()
  //     const filterData = fullTimeData?.filter(dt =>
  //       dt?.first_name.includes(employeeName)
  //     )
  //     setClassData(filterData)
  //   } else {
  //     setClassData(fullTimeData)
  //   }
  //   setPage(1)
  // }, [watch("employee_name")])

  const handleGenerateConformation = () => {
    if (employeeCheck?.length) {
      setIsGenerate(true)
      toggle()
    } else {
      toastError("Atleast Select Anyone Employee to Generate Payroll")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="HRMS"
              breadcrumbObject={[
                { title: "HRMS", link: "/hrms" },
                { title: "Timesheet", link: "/hrms-timesheet" },
                { title: "Add Timesheet", link: "/hrms-timesheet" },
              ]}
            />
            <div>
              <LoadingButton
                form={`ev-form`}
                color={"primary"}
                // loading={isLoading1}
                // disabled={isLoading1}
                className="py-1 pt-1"
                // type="submit"
                onClick={handleGenerateConformation}
              >
                Generate
              </LoadingButton>
            </div>
          </div>
          <Card className="me-2 px-2 pb-2">
            {/* <FormProvider {...methods} id={`ev-form`}>
              <Form
                id={`ev-form`}
                key={1}
                onSubmit={handleSubmit(handleRegistration)}
              > */}
            {/* <Row className="py-3 pt-4"> */}
            {/* <Col sm={3}> */}
            {/* <FormGroup className="mb-4">
                  <Label>
                    Year and Month
                    <span className="mandatory">*</span>
                  </Label>
                  <UFMonthYear
                    control={control}
                    name={"year_month"}
                    invalid={!!errors.year_month}
                    month={true}
                    placeholder={"Select Date"}
                  />
                  <FormFeedback invalid>
                    {errors.year_month?.message}
                  </FormFeedback>
                </FormGroup> */}
            {/* <FormGroup className="mb-4">
                      <Label>
                        Project<span className="mandatory">*</span>
                      </Label>
                      <ProjectNo
                        control={control}
                        name={"project_id"}
                        placeholder={"Select"}
                        invalid={!!errors.project_id}
                        target={"body"}
                      />
                      <FormFeedback invalid>
                        {errors.project_id?.message}
                      </FormFeedback>
                    </FormGroup> */}
            {/* </Col> */}
            {/* <Col sm={3}>
                <FormGroup className="mb-4">
                  <Label>
                    Department<span className="mandatory">*</span>
                  </Label>
                  <Department
                    control={control}
                    placeholder="Select the Department"
                    name={"department_id"}
                    invalid={!!errors.department_id}
                    target={"body"}
                    param="department"
                  />
                  <FormFeedback invalid>
                    {errors.department_id?.message}
                  </FormFeedback>
                </FormGroup>
              </Col> */}
            {/* <Col sm={3}>
                <FormGroup className="mb-4">
                  <Label>
                    <span className="mandatory"></span>
                  </Label>
                </FormGroup>
              </Col> */}
            {/* </Row> */}

            <Row className="pt-4">
              <Col>
                <div className="today-bold">
                  {moment(loggedUser?.settings?.hrms_att_date).format(
                    "MMMM YYYY"
                  )}
                </div>
              </Col>
              <Col className="text-end me-2">
                <span>Note: </span>
                <span className="ps-3">
                  <img src={present} alt="" className="me-2" />
                  Present
                </span>
                <span className="ps-3">
                  <img src={halfDay} alt="" className="me-2" />
                  Half Day Present
                </span>
                <span className="ps-3">
                  <img src={absent} alt="" className="me-2" /> Absent
                </span>
                <span className="ps-3">
                  <img src={weeekend} alt="" className="me-2" />
                  Weekend
                </span>
              </Col>
            </Row>
            <hr className="table-divide" />
            <Row className="pt-2 me-2">
              <Col sm={2}>
                <FormGroup className="">
                  <UFInput
                    control={control}
                    name={"employee_name"}
                    placeholder={"Employee Name"}
                  />
                </FormGroup>
              </Col>
              <Col sm={9}></Col>
              {/* <Col sm={2}>
                <FormGroup className="">
                  <UFMonthYear
                    control={control}
                    name={"year_month"}
                    invalid={!!errors.year_month}
                    month={true}
                    placeholder={"Select Date"}
                    defaultValue={moment().format("YYYY-MM-DD")}
                  />
                  <FormFeedback invalid>
                    {errors.year_month?.message}
                  </FormFeedback>
                </FormGroup>
              </Col> */}
              <Col sm={1}>
                <PageNumLi
                  control={control}
                  name={"view"}
                  // onSelectChange={e => handleStatus(e)}
                />
              </Col>
            </Row>

            {/* </Form>
            </FormProvider> */}
            <hr className="table-divide" />
            <Row className="ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData}
                    paginate={classPage}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="3% 8% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9% 2.9%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} size="md" unmountOnClose={false}>
        <FormProvider {...methodsForm2} id={`leave-form`}>
          <Form
            id={`leave-form`}
            key={2}
            onSubmit={handleSubmitForm2(handleRegistrationEdit)}
          >
            <>
              <ModalHeader className="custom-modal-header">
                <div className="d-flex justify-content-between">
                  <h5 className="">
                    {isGenerate && (
                      <span className={"warning-icon"}>
                        <i className={" uil-exclamation-octagon"}></i>{" "}
                      </span>
                    )}
                    {isGenerate ? `Confirmation` : "Edit Attendance"}
                  </h5>
                  <img
                    src={closeIcon}
                    alt=""
                    onClick={toggle}
                    width="10"
                    role="button"
                  />
                </div>
              </ModalHeader>
              <ModalBody className="pb-4">
                {isGenerate ? (
                  <>
                    This is a friendly reminder that you are currently
                    generating payroll for{" "}
                    {moment(todayDate).format("MMMM YYYY")}. Please ensure that
                    Attendance are accurate to avoid any discrepancies in
                    employee Payroll.
                  </>
                ) : (
                  <>
                    <Row className="py-3 ms-4">
                      <Col lg={4} className="secondary-text">
                        <Row>Employee</Row>
                        <Row className="primary-text">
                          {" "}
                          {viewData?.first_name}
                        </Row>
                      </Col>

                      <Col lg={4} className="secondary-text">
                        <Row>Designation</Row>
                        <Row className="primary-text">
                          {" "}
                          {viewData?.designation?.name}
                        </Row>
                      </Col>

                      <Col lg={4} className="secondary-text">
                        <Row>Date</Row>
                        <Row className="primary-text">
                          {viewData?.date}-{moment(todayDate).format("MM-YYYY")}
                        </Row>
                      </Col>
                    </Row>
                    <Row className="py-3 ms-3 pb-1">
                      <Col
                        lg={"auto"}
                        onClick={() => handleLeaveCheck(leaveCheck)}
                      >
                        <div>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_leave"}
                            checked={leaveCheck}
                          />
                          <Label className="ms-2">Leave</Label>
                        </div>
                      </Col>
                    </Row>
                    {leaveCheck && (
                      <Row className="py-1 ms-5">
                        {/* <Col
                          lg={3}
                          onClick={() => handleHalfDayCheck(halfDayCheck)}
                          className="d-flex align-items-center"
                        >
                          <div>
                            <input
                              type="checkbox"
                              className="ever-rise-checkbox"
                              name={"is_halfDay"}
                              checked={halfDayCheck}
                            />
                            <Label className="ms-2">Half Day</Label>
                          </div>
                        </Col> */}
                        <Col lg={3} className="d-flex align-items-center">
                          <Label> Leave Type :</Label>
                        </Col>
                        <Col lg={5} className="d-flex align-items-center">
                          <div className="w-100">
                            <LeaveType
                              control={controlForm2}
                              placeholder="Select "
                              name={"leave_id"}
                              invalid={!!errorsForm2.leave_id}
                            />
                            <FormFeedback invalid>
                              {errorsForm2.leave_id?.message}
                            </FormFeedback>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {/* <Row className="py-3 ms-3 pb-1">
                      <Col lg={"auto"} onClick={() => handleOtCheck(otCheck)}>
                        <div>
                          <input
                            type="checkbox"
                            className="ever-rise-checkbox"
                            name={"is_leave"}
                            checked={otCheck}
                          />
                          <Label className="ms-2">OT</Label>
                        </div>
                      </Col>
                    </Row> */}
                    {/* {otCheck && (
                      <Row className="py-1 ms-5">
                        <Col lg={"auto"} className="d-flex align-items-center">
                          <Label> Hrs :</Label>
                        </Col>
                        <Col lg={"auto"} className="d-flex align-items-center">
                          <div>
                            <UFInput
                              control={controlForm2}
                              placeholder="Enter the Hours"
                              name={"hours"}
                              invalid={!!errorsForm2.hours}
                            />
                            <FormFeedback invalid>
                              {errorsForm2.hours?.message}
                            </FormFeedback>
                          </div>
                        </Col>
                      </Row>
                    )} */}
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <LoadingButton
                  form={`leave-form`}
                  color={"primary"}
                  loading={isLoading2}
                  disabled={isLoading2}
                  type="submit"
                >
                  {isGenerate ? "Generate" : "Update"}
                </LoadingButton>
              </ModalFooter>
            </>
          </Form>
        </FormProvider>
      </Modal>
    </React.Fragment>
  )
}

export default TimeSheet
