import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"
import {
  getNotification,
  readAllNotification,
} from "../../../services/notification/notification"
import Pusher from "pusher-js"
import { useSelector } from "react-redux"
import moment from "moment"
import { use } from "react"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [notificationList, setNotificationList] = useState(false)
  const loggedUser = useSelector(state => state.Login.loggedUser)

  const pusherKey = process.env.REACT_APP_PUSHER
  const pusherCluster = process.env.REACT_APP_PUSHER_CLUSTER

  const fetchNotifications = async () => {
    const response = await getNotification({ params: `sizePerPage=5` })
    setNotificationList(response)
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (!loggedUser?.data?.id) return

    const pusher = new Pusher("da936c6d620ae409576a", {
      cluster: "ap2",
      encrypted: true,
    })

    const channel = pusher.subscribe(`my-channel.${loggedUser.data.id}`) // Replace with your actual channel name
    channel.bind("my-event", () => {
      // Replace 'your-event-name' with the actual event name
      fetchNotifications()
    })
    fetchNotifications()

    return () => {
      channel.unbind_all()
      channel.unsubscribe()
      pusher.disconnect()
    }
  }, [menu])

  const handleRead = async () => {
    try {
      const response = await readAllNotification() // Await the API call
      // if (response?.status === "success") {
      fetchNotifications() // Ensure this is correctly defined and callable
      // } else {
      //   console.error("Failed to mark notifications as read:", response)
      // }
    } catch (error) {
      console.error("Error in handleRead:", error)
    }
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="uil-bell"></i>
          <span className="badge bg-danger rounded-pill">
            {notificationList?.unreadCount ? notificationList?.unreadCount : 0}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16">
                  {" "}
                  {props.t("Notifications")}{" "}
                </h6>
              </Col>
              <div className="col-auto">
                <div onClick={() => handleRead()} className="small pointer">
                  {" "}
                  Mark all as read
                </div>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notificationList?.data?.data?.length ? (
              notificationList?.data?.data.map((itm, key) => (
                <div
                  onClick={() =>
                    navigate(itm?.slug ? `/${itm?.slug}` : "#", {
                      state: itm,
                    })
                  }
                  className="text-dark notification-item pointer"
                  key={key}
                >
                  <div className="d-flex align-items-start">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="uil-shopping-basket"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <h6 className="mt-0 mb-1">
                        {props.t(itm?.title || "No Title")}
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          {props.t(itm?.message || "No Message")}
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline"></i>{" "}
                          {moment(itm?.created_at).fromNow()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-muted">No Notifications</div>
            )}

            {/* <Link to="" className="text-dark notification-item">
              <div className="d-flex align-items-start">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    <i className="uil-shopping-basket"></i>
                  </span>
                </div>
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">
                    {props.t("Your order is placed")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("If several languages coalesce the grammar")}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline"></i>{" "}
                      {props.t("3 min ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-dark notification-item">
              <div className="d-flex align-items-start">
                <img
                  src={avatar3}
                  className="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">James Lemire</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("It will seem like simplified English") + "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-dark notification-item">
              <div className="d-flex align-items-start">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    <i className="bx bx-badge-check" />
                  </span>
                </div>
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">
                    {props.t("Your item is shipped")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("If several languages coalesce the grammar")}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />{" "}
                      {props.t("3 min ago")}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="" className="text-dark notification-item">
              <div className="d-flex align-items-start">
                <img
                  src={avatar4}
                  className="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">Salena Layfield</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(
                        "As a skeptical Cambridge friend of mine occidental"
                      ) + "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link> */}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 text-center"
              to="/notification"
            >
              <i className="uil-arrow-circle-right me-1"></i>{" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
