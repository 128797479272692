import React, { useEffect, useState } from "react"
import { Card, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import action from "../../../assets/icons/action.svg"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import {
  fetchFinanceCategory,
  updateCatStatus,
} from "../../../services/finance/finance"
import { capitalize } from "lodash"
import {
  getDocumentNotification,
  getNotification,
} from "../../../services/notification/notification"
import NotificationFilter from "../filter"
import moment from "moment"
import { useLocation } from "react-router-dom"

const ExpiredNotificationList = () => {
  document.title = "Expired Document | Everise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const location = useLocation()

  const { loggedUser } = useSelector(LoginProperties)

  console.log(`location`, location)

  const [page, setPage] = useState("")

  const [series, setSeries] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [expChange, setExp] = useState("")
  const [count, setCount] = useState(0)
  const [editSeries, setEditSeries] = useState({ modal: false, value: "" })

  useEffect(() => {
    const fetchData = async () => {
      const response = await getDocumentNotification({
        params: `${"sizePerPage=16"}${page ? `&page=${page}` : ""}${
          location?.state || expChange
            ? `&expiryStatus=${
                expChange
                  ? expChange
                  : location?.state?.title == "Document Expiry Alert"
                  ? "expiring"
                  : "expired"
              }`
            : ""
        }`,
      })
      setSeries(response)
    }
    fetchData()
  }, [count, status, search, page, expChange])

  const handleEdit = item => {
    setEditSeries({ value: item, modal: true })
  }

  const handleStatus = async id => {
    try {
      const response = await updateCatStatus(id)
      toastSuccess(response?.message)
      setCount(count + 1)
    } catch (err) {
      toastError(err?.message)
    }
  }

  const columns = [
    {
      label: "Document Title",
      renderCell: item =>
        item?.document_title
          ? capitalize(item?.document_title?.name)
          : item?.vat
          ? "VAT"
          : item?.trade
          ? "Trade"
          : "-",
    },
    {
      label: "Name",
      renderCell: item =>
        item?.employee
          ? capitalize(item?.employee?.first_name)
          : item?.vat
          ? item?.vat?.name
          : item?.trade
          ? item?.trade?.name
          : "-",
    },
    {
      label: "Expiring Date",
      renderCell: item =>
        item?.created_at ? moment(item?.expiry_date).format("DD-MM-YYYY") : "-",
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  const handleUpdate = () => {
    setCount(count + 1)
  }

  const handleResetValue = () => {
    setEditSeries("")
  }

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }
  const handleExpChange = data => {
    setExp(data)
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Notification Expiry"
              breadcrumbObject={[
                { title: "Notification Expiry", link: "#" },
                // { title: "Finance Category", link: "/finance-category" },
              ]}
            />
          </div>
          <Card className="me-2 px-2 pb-2">
            <NotificationFilter
              handleSearchChange={handleSearchChange}
              handleExpChange={handleExpChange}
              showExport={false}
              expiryDrop={true}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {series?.data && (
                  <UpdateTable
                    className="mt-5"
                    nodes={series?.data}
                    paginate={series}
                    CurrentPage={page}
                    columns={columns}
                    handlePagination={handlePaginate}
                    tableWidth="40% 30% 30%"
                    mobileWidth="40% 40% 40%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ExpiredNotificationList
