import { get, post } from "../../helpers/api_helper"

//Country
export const getCountryList = () => {
  return get(`country/list`)
}
//Payment Mode
export const getPaymentModeList = () => {
  return get(`paymentmethod/list`)
}
//customer type
export const getCustomerTypeList = () => {
  return get(`customer-type`)
}
//customer List
export const getCustomerList = () => {
  return get(`customer/list`)
}
//Estimation Category
export const getEstimationCategoryList = (classId, estId) => {
  return get(`estimation/cat?class_id=${classId}&expense_type=${estId}`)
}

//Estimation Category
export const getEstimationCategoryMaterialList = (classId, estId) => {
  return get(`estimation/cat?class_id=${classId}&expense_type=${estId}`)
}
//Estimation Sub Category
export const getEstimationSubCategoryList = id => {
  return get(`estimation/cat-sub/${id}`)
}
//Estimation Product
export const getEstimationProductList = id => {
  return get(`estimation/product/${id}`)
}
//Location
export const getLocationList = () => {
  return get(`locations/list`)
}
//Payment Mode
export const getEstimationClassList = data => {
  return get(`estimation/class/${data}`)
}

// Ticket Class
export const getTicketClassList = () => {
  return get(`ticket-class/list`)
}

// input fields
export const getInputFields = () => {
  return get(`input-type`)
}

// grade
export const getGradeFields = () => {
  return get(`grade-updated-list/list`)
}

// benefits list
export const benefitsList = () => {
  return get(`benefits/addons-list`)
}
// benefits list
export const benefitsLists = () => {
  return get(`benefits/list`)
}

// entitlement list
export const entitlementList = () => {
  return get(`entitlement/addons-list`)
}
// entitlement list
export const entitlementLists = () => {
  return get(`entitlement/list`)
}

// payroll component list
export const payrollList = () => {
  return get(`payroll-component/list`)
}

// grade for payroll
export const gradePayroll = () => {
  return get(`grade-payroll/list`)
}
// Department
export const getDepartmentList = () => {
  return get(`department/list`)
}
// Designation
export const getDesignationList = () => {
  return get(`designation/list`)
}
// ConfirmationAfter
export const getConfirmationAfterList = () => {
  return get(`confirmation-after`)
}
// CostCenter
export const getCostCenterList = () => {
  return get(`cost-center`)
}
// Employment TYpe
export const getEmploymentTypeList = () => {
  return get(`employment-types`)
}
// Gender list
export const getGenderList = () => {
  return get(`gender-type`)
}
// Marital status
export const getMaritalList = () => {
  return get(`marital-status`)
}
// Languages
export const getLanguagesList = () => {
  return get(`language/list`)
}
// blood-group
export const getBloodGroupList = () => {
  return get(`blood-group`)
}
// ReligionList
export const getReligionList = () => {
  return get(`religions/list`)
}
// Employee Type
export const getEmployeeTypeList = () => {
  return get(`employee-type/list`)
}
// Sponsored Type
export const getSponsoredList = () => {
  return get(`sponsors/list`)
}
// grade
export const getEmployeeGrade = () => {
  return get(`grade/list`)
}
//  Educational Degree
export const getEducationalDegree = () => {
  return get(`education-degree/list`)
}
//  Educational Department
export const getEducationalDepartment = () => {
  return get(`education-department/list`)
}
//  SalaryType
export const getSalaryType = () => {
  return get(`salary-type`)
}
//  SalaryType
export const getCampCodeList = () => {
  return get(`camp-code/list`)
}
//  Employee List
export const getEmployeeList = () => {
  return get(`employee-details/list`)
}

// Role List
export const getRoleList = data => {
  return get(data)
}
// Project List
export const getProjectList = () => {
  return get(`project-master/list`)
}
export const getMaterialProjectList = () => {
  return get(`project-master/updated-list`)
}
export const getPriorityList = () => {
  return get(`priority-types`)
}
// export const getRequestList = () => {
//   return get(`material-request/list`)
// }
export const getRequestList = isApproved => {
  return get(`material-requests/list${isApproved ? `?status=2` : ``}`)
}
export const getPORequestList = ({ withoutRequest }) => {
  return get(
    `material-request/updated-list?${withoutRequest ? "without_request=0" : ""}`
  )
}
// supplier list
export const getSupplierList = () => {
  return get(`suppliers/list`)
}
export const getProductcode = () => {
  return get(`products/list`)
}
//document
export const getDocumentList = () => {
  return get(`documents/list`)
}

//document
export const getPurchaseList = () => {
  return get(`purchase-oreder/list`)
}
export const getGRNPurchaseList = forInvoice => {
  return get(
    `purchase-oreder/updated-list${forInvoice ? `?invoice_status` : ``}`
  )
}
//grn list
export const getGrnList = () => {
  return get(`grn/list`)
}

//leave Type

export const getLeaveTypeList = () => {
  return get(`leave-type/list`)
}
//leave Managemant

export const getLeaveManageList = () => {
  return get(`leave-request/list`)
}
//leave Managemant

export const getOtherPaymentsList = () => {
  return get(`other-payments/list`)
}

// state dropdown
export const getStateList = id => {
  return get(`state/filter-list/${id}`)
}

// bank dropdown
export const getBankList = () => {
  return get(`banks-types`)
}
// Modules dropdown
export const getModuleList = () => {
  return get(`permissions/parent-list`)
}

// product code with project number
export const getProductCodeProjectNum = projectNo => {
  return get(`central-store/project-products?project_id=${projectNo}`)
}

// user dropdown

export const getUsersList = () => {
  return get(`users/list`)
}

// material req dropdown
export const getMaterialProduct = id => {
  return get(`project-master/productlist/${id}`)
}

// creditPeriod
export const getCreditPeriod = () => {
  return get(`credit-period`)
}

// role
export const getRoleLists = () => {
  return get(`roles/list`)
}
// financeCat
export const getFinanceCatLists = () => {
  return get(`finance-cat/list`)
}

// Grn no
export const getGrnNo = ({ param, projectId }) => {
  return post(
    `grn/costing-data?${param && !projectId ? `parm=${param}` : ""}${
      projectId ? `&parm2=${projectId}` : ""
    }`
  )
}
// Costcenter
export const getCostCenter = param => {
  return get(
    `project-master/updated-costcenter-list?${param ? `parm=${param}` : ""}`
  )
}
// finance sub-cat
export const getFinanceSubCat = () => {
  return get(`finance-sub-cat/list`)
}
// finance sub-cat-id
export const getFinanceSubCatId = id => {
  return get(`finance-sub-cat/filter-list/${id}`)
}
// GRN Costing List
export const getGRNCostingList = () => {
  return get(`account-payables/list`)
}
// TH List
export const getTransactionHistory = () => {
  return get(`transaction-history/list`)
}
// CC Li
export const getCCList = () => {
  return get(`cost-center/list`)
}
// AR Li
export const getARList = () => {
  return get(`account-recivables/list`)
}
// Vaccine List
export const getVaccineList = () => {
  return get(`vaccin/list`)
}
// Relationship List
export const getRelationshipList = () => {
  return get(`relationships/list`)
}
// Booster List
export const getBoosterList = () => {
  return get(`booster/list`)
}
// Account Payables List
export const getAccPayablesList = id => {
  return get(`accounts/filter-list-payable/${id}`)
}
// Account Recievable List
export const getAccRecievableList = id => {
  return get(`accounts/filter-list-receivable/${id}`)
}
// Account Payables List
export const getGrnCostingLists = id => {
  return get(`grn-costing/filter-list/${id}`)
}
// Accounts List
export const getAccountsLists = id => {
  return get(`accounts/list/${id ? id : ""}`)
}
// Transaction List
export const getTransactionLists = () => {
  return get(`transaction-type/list`)
}
// Gratuity List
export const getGratuityLists = () => {
  return get(`gratuity/list`)
}

// Reliving Type
export const fetchRelivingControlLists = () => {
  return get(`reliving-types`)
}

// Account Group
export const fetchFinanceGroupLi = parentChk => {
  return get(`account-groups${parentChk ? "?parent_id_notnull" : ""}`)
}

//data type lists
export const getFinanceDataTypesLi = () => {
  return get(`group-fields/list`)
}

//voucher list
export const getFinanceVoucherLi = () => {
  return get(`voucher/list`)
}

// Account Ledger
export const fetchFinanceLedgersLi = (filSupplier, ledgerTypes) => {
  return get(
    `ledger/list${filSupplier ? `?supplier_id=${filSupplier}` : ``}${
      ledgerTypes
        ? `?types=${
            ledgerTypes == "asset"
              ? `1`
              : ledgerTypes == "liabilty"
              ? `2`
              : ledgerTypes == "income"
              ? `3`
              : ledgerTypes == "expence"
              ? `4`
              : ledgerTypes == "asset&expence"
              ? `1,4`
              : ``
          }`
        : ``
    }`
  )
}

//voucher entry list
export const getFinanceVoucherEntryLi = ({ voucherType, partyName }) => {
  console.log(`voucherType`, voucherType, partyName)

  return post(
    `voucher-entry/list`,
    {
      vaucher_type: voucherType ? voucherType : [],
      party_id: partyName ? partyName : "",
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )

  // return post(
  //   `voucher-entry/list${voucherType ? `?voucher_type=[${voucherType}]` : ""}${
  //     partyName ? `&part_id=${partyName}` : ""
  //   }`
  // )
}

//Stock Product list
export const getProductStockLi = () => {
  return get(`central-store/list`)
}

export const getRFQList = approvedStatus => {
  return get(`rfq${approvedStatus ? `?status=2` : ``}`)
}
