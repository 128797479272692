import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Col, Row, Card, UncontrolledPopover, PopoverBody } from "reactstrap"
import UpdateTable from "../../../components/Common/UpdatedTable"
import action from "../../../assets/icons/action.svg"
import { toastSuccess } from "../../../helpers/msg_helper"
import SupplierFilter from "./filter"
import { Link, useNavigate } from "react-router-dom"
import {
  searchSupplier,
  updateSupplierStatus,
} from "../../../services/procurement/supplier"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const Supplier = () => {
  document.title = "Supplier | Ever Rise"

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    loggedUser: login.loggedUser,
  }))

  const { loggedUser } = useSelector(LoginProperties)

  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(0)
  const [classData, setClassData] = useState()
  const [status, setStatus] = useState("")
  const [search, setSearch] = useState("")
  const [country, setCountry] = useState("")
  const [supplierFil, setSupplier] = useState("")
  const [page, setPage] = useState("")
  const [financial, setFinancial] = useState(true)

  const handleActivate = item => {
    const updateStatus = async item => {
      const response = await updateSupplierStatus(item)
      toastSuccess(response?.message)
      setRefresh(refresh + 1)
    }
    updateStatus(item)
  }

  const columns = [
    {
      label: "Supplier Code",
      renderCell: item => {
        return (
          <>
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "supplier_view"
            ) ? (
              <div
                onClick={() =>
                  navigate(`/procurement-supplier/view/${item?.id}`)
                }
                className="pointer fw-bold"
              >
                {item?.suppliers_code}
              </div>
            ) : (
              <div>{item?.suppliers_code}</div>
            )}
          </>
        )
      },
    },
    {
      label: "Supplier Name",
      renderCell: item => item?.name,
      sortKey: "name",
    },
    {
      label: "Email Id",
      renderCell: item => item?.email_id ? item?.email_id : "-",
    },
    {
      label: "Contact Number",
      renderCell: item => "+" + item?.contact_number,
    },
    {
      label: "Country",
      renderCell: item => item?.country?.name,
    },
    {
      label: "Status",
      renderCell: item => (
        <div>
          <span
            className={`font-13 ${
              item?.status === 1 ? "status-active" : "status-inactive"
            }`}
          >
            {item?.status === 1 ? "Active" : "Inactive"}
          </span>
        </div>
      ),
    },
    {
      label: "Action",
      renderCell: item => (
        <>
          <div>
            <span id={`PopoverLegacy-${item?.id}`}>
              <img src={action} alt="" className="pointer" />
            </span>
          </div>
          <UncontrolledPopover
            placement="bottom"
            target={`PopoverLegacy-${item?.id}`}
            trigger="legacy"
          >
            <PopoverBody>
              {item?.status === 1 &&
                loggedUser?.permission?.some(
                  permissionItem => permissionItem?.name === "supplier_edit"
                ) && (
                  <div
                    className="table-popup-content"
                    onClick={() =>
                      navigate(`/procurement-supplier/edit/${item?.id}`)
                    }
                  >
                    Edit
                  </div>
                )}
              <div
                className={`${
                  item?.status === 1 ? "popover-border" : ""
                } table-popup-content`}
                onClick={() => handleActivate(item?.id)}
              >
                {item?.status === 1 ? "Deactivate" : "Activate"}
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),
    },
  ]

  const handlePaginate = page => {
    setPage(page)
  }

  useEffect(() => {
    const fetchClass = async () => {
      const response = await searchSupplier({
        params: `${status == "0" || status == "1" ? `&status=${status}` : ""}${
          search ? `&searchText=${search}` : "&searchText"
        }${
          country ? `&country_id=${country}` : ""
        }${`&financial=${financial}`}${page ? `&page=${page}` : ""}${
          supplierFil ? `&supplier_code=${supplierFil}` : ""
        }${`&sizePerPage=16`}`,
      })
      setClassData(response)
    }
    fetchClass()
  }, [refresh, status, search, country, page, supplierFil, financial])

  const handleStatusChange = id => {
    setStatus(id)
    setPage(1)
  }
  const handleCountryChange = id => {
    setCountry(id)
    setPage(1)
  }
  const handleSupplierChange = id => {
    setSupplier(id)
  }
  const handleSearchChange = data => {
    setSearch(data)
    setPage(1)
  }

  const handleFinacialChange = id => {
    setFinancial(id)
    setPage(1)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Breadcrumbs
              title="Procurement"
              breadcrumbObject={[
                { title: "Procurement", link: "/procurement" },
                { title: "Supplier", link: "/procurement-supplier" },
              ]}
            />
            {loggedUser?.permission?.some(
              permissionItem => permissionItem?.name === "supplier_create"
            ) && (
              <Link
                to="/procurement-supplier/add"
                className={"px-2 mb-3 btn btn-primary"}
              >
                {"+ Add Supplier"}
              </Link>
            )}
          </div>
          <Card className="me-2 px-2 pb-2">
            <SupplierFilter
              handleStatusChange={handleStatusChange}
              handleSearchChange={handleSearchChange}
              handleCountryChange={handleCountryChange}
              handleSupplierChange={handleSupplierChange}
              handleFinacialChange={handleFinacialChange}
              sampleUrl={"download/suppliers.xlsx"}
              importUrl={"suppliers/import"}
              isImport={false}
            />
            <hr className="table-divide" />
            <Row className="mt-2 ever-rise-table">
              <Col xs={12}>
                {classData && (
                  <UpdateTable
                    className="mt-5"
                    nodes={classData?.data?.data}
                    paginate={classData?.data}
                    columns={columns}
                    CurrentPage={page}
                    handlePagination={handlePaginate}
                    tableWidth="15% 15% 15% 15% 15% 15% 10%"
                    mobileWidth="30% 30% 30% 30% 30% 30% 10%"
                  />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Supplier
