import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import LoadingButton from "../../../components/Common/LoadingButton"
import {
  fetchSupplier,
  tradeDocDownload,
} from "../../../services/procurement/supplier"
import { useNavigate, useParams } from "react-router-dom"
import DownloadIcon from "../../../assets/icons/download.svg"
import ViewIcon from "../../../assets/icons/document-view.svg"
import closeIcon from "../../../assets/icons/close.svg"

const ViewSupplier = ({ fromDashboard, viewValue }) => {
  const [supplier, setSupplier] = useState()
  const { id } = useParams()
  const navigate = useNavigate()

  const imgUrl = process.env.REACT_APP_IMG_APIKEY

  const [modal, setModal] = useState(false)
  const [fileView, setFileView] = useState()

  const ViewId = fromDashboard ? viewValue?.value?.supplier_id : id
  console.log("viewValue  :", viewValue)

  useEffect(() => {
    const fetchData = async id => {
      const response = await fetchSupplier(id)
      setSupplier(response?.data)
    }
    fetchData(ViewId)
  }, [])

  const handleTradeDownload = ({ path, url, downloadName, extension }) => {
    const handleDownload = async () => {
      const responce = await tradeDocDownload({
        url: url,
        path,
        downloadName: downloadName,
        extension: extension,
      })
    }
    handleDownload()
  }

  const getExtension = filePath => {
    const parts = filePath.split(".")
    return parts[1]
  }

  const toggle = type => {
    setModal(!modal)
    setFileView(
      type?.type == "vat"
        ? supplier?.vat?.[0]?.file_path
        : supplier?.trade?.[0]?.file_path
    )
    console.log(`coming in`, type, supplier, modal)
  }

  return (
    <React.Fragment>
      <div className={`page-content ${fromDashboard && "pt-0"}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between me-2">
            {!fromDashboard && (
              <Breadcrumbs
                title="Procurement"
                breadcrumbObject={[
                  { title: "Procurement", link: "/procurement" },
                  { title: "Suppliers", link: "/procurement-supplier" },
                  {
                    title: "View Supplier",
                    link: "",
                  },
                ]}
              />
            )}
            {!fromDashboard && (
              <LoadingButton
                form={`supplier-form`}
                color={"primary"}
                className={"px-2 mb-3 btn btn-primary"}
                type="submit"
                onClick={() => navigate(`/procurement-supplier/edit/${ViewId}`)}
              >
                Edit
              </LoadingButton>
            )}
          </div>
          <Container fluid className="ps-lg-0">
            <Card className="supplier-card">
              <div className="supplier-view-head px-4 py-2">
                Supplier Details
              </div>
              <Row className="px-4 py-5">
                <Col sm={11}>
                  <Row>
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">Supplier code</div>
                      <div className="supplier-td-body">
                        {supplier ? supplier?.suppliers_code : "-"}
                      </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">Supplier name</div>
                      <div className="supplier-td-body capitalize-letter">
                        {supplier ? supplier?.name : "-"}
                      </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">Email id</div>
                      <div className="supplier-td-body">
                        {supplier?.email_id ? supplier?.email_id : "-"}
                      </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">Contact number</div>
                      <div className="supplier-td-body">
                        {supplier ? "+" + supplier?.contact_number : "-"}
                      </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">Country</div>
                      <div className="supplier-td-body">
                        {supplier ? supplier?.country?.name : "-"}
                      </div>
                    </Col>
                    {/* <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">Payment Mode</div>
                      <div className="supplier-td-body">
                        {supplier ? supplier?.payment?.name : "-"}
                      </div>
                    </Col> */}
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">Credit Period</div>
                      <div className="supplier-td-body">
                        {supplier ? supplier?.creditperiod?.label : "-"}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">Currency</div>
                      <div className="supplier-td-body">
                        {supplier ? supplier?.currency?.name : "-"}
                      </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">VAT No</div>
                      <div className="supplier-td-body">
                        {supplier?.vat_no ? supplier?.vat_no : "-"}
                      </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">Address</div>
                      <div className="supplier-td-body capitalize-letter">
                        {supplier ? supplier?.address : "-"}
                      </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">VAT Certificate</div>
                      <div className="supplier-td-body supplier-file">
                        {supplier ? (
                          <>
                            <span
                              className="pointer view-eye"
                              onClick={() => toggle({ type: "vat" })}
                            >
                              <img src={ViewIcon} />
                            </span>
                            <span
                              className="pointer view-eye"
                              onClick={() => {
                                handleTradeDownload({
                                  id: supplier?.vat?.[0]?.id,
                                  path: supplier?.vat?.[0]?.file_path
                                    ?.split("/")
                                    .pop(),
                                  url: `suppliers-trade-download/${supplier?.vat?.[0]?.id}`,
                                  downloadName: "VAT_Certificate",
                                  extension: getExtension(
                                    supplier?.vat?.[0]?.file_path
                                  ),
                                })
                              }}
                            >
                              <img src={DownloadIcon} />
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                        {/* <span
                          className="pointer"
                          onClick={() => {
                            handleTradeDownload({
                              id: supplier?.vat?.[0]?.id,
                              path: supplier?.vat?.[0]?.file_path
                                ?.split("/")
                                .pop(),
                              url: `suppliers-trade-download/${supplier?.vat?.[0]?.id}`,
                              downloadName: "VAT_Certificate",
                              extension: getExtension(
                                supplier?.vat?.[0]?.file_path
                              ),
                            })
                          }}
                        >
                          {supplier
                            ? supplier?.vat?.[0]?.file_path?.split("/").pop()
                            : "-"}
                        </span> */}
                      </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}>
                      <div className="supplier-td-head">Trade Licence</div>
                      <div className="supplier-td-body supplier-file">
                        {supplier ? (
                          <>
                            <span
                              className="pointer view-eye"
                              onClick={() => toggle({ type: "trade" })}
                            >
                              <img src={ViewIcon} />
                            </span>
                            <span
                              className="pointer view-eye"
                              onClick={() => {
                                handleTradeDownload({
                                  id: supplier?.trade?.[0]?.id,
                                  path: supplier?.trade?.[0]?.file_path
                                    ?.split("/")
                                    .pop(),
                                  url: `suppliers-trade-download/${supplier?.trade?.[0]?.id}`,
                                  downloadName: "Trade_Licence",
                                  extension: getExtension(
                                    supplier?.trade?.[0]?.file_path
                                  ),
                                })
                              }}
                            >
                              <img src={DownloadIcon} />
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                        {/* <span
                          className="pointer"
                          onClick={() => {
                            handleTradeDownload({
                              id: supplier?.trade?.[0]?.id,
                              path: supplier?.trade?.[0]?.file_path
                                ?.split("/")
                                .pop(),
                              url: `suppliers-trade-download/${supplier?.trade?.[0]?.id}`,
                              downloadName: "Trade_Licence",
                              extension: getExtension(
                                supplier?.trade?.[0]?.file_path
                              ),
                            })
                          }}
                        >
                          {supplier
                            ? supplier?.trade?.[0]?.file_path?.split("/").pop()
                            : "-"}
                        </span> */}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="supplier-sub-view-head px-4 py-2">
                Contact Person
              </div>
              <Row className="px-4 ">
                <Col lg={11} md={12}>
                  {supplier?.contact?.map((dt, index) => (
                    <Row key={`contact${index}`}>
                      <Col
                        lg={2}
                        md={6}
                        className={`${
                          supplier?.contact.length > 1 &&
                          index + 1 < supplier?.contact.length
                            ? "table-border-line py-4"
                            : "py-4"
                        }`}
                      >
                        <div className="supplier-td-head">Contact Name</div>
                        <div className="supplier-td-body">
                          {dt?.supplier_contact_name}
                        </div>
                      </Col>
                      <Col
                        lg={2}
                        md={6}
                        className={`${
                          supplier?.contact.length > 1 &&
                          index + 1 < supplier?.contact.length
                            ? "table-border-line py-4"
                            : "py-4"
                        }`}
                      >
                        <div className="supplier-td-head">Contact Email ID</div>
                        <div className="supplier-td-body">{dt?.email_id}</div>
                      </Col>
                      <Col
                        lg={2}
                        md={6}
                        className={`${
                          supplier?.contact.length > 1 &&
                          index + 1 < supplier?.contact.length
                            ? "table-border-line py-4"
                            : "py-4"
                        }`}
                      >
                        <div className="supplier-td-head">
                          Contact Designation
                        </div>
                        <div className="supplier-td-body">
                          {dt?.designation}
                        </div>
                      </Col>
                      <Col
                        lg={2}
                        md={6}
                        className={`${
                          supplier?.contact.length > 1 &&
                          index + 1 < supplier?.contact.length
                            ? "table-border-line py-4"
                            : "py-4"
                        }`}
                      >
                        <div className="supplier-td-head">
                          Contact Phone Number
                        </div>
                        <div className="supplier-td-body">
                          {"+" + dt?.phone_no}
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
              {supplier?.bank_details?.length > 0 && (
                <>
                  <div className="supplier-sub-view-head px-4 py-2">
                    Bank Details
                  </div>
                  <Row className="px-4">
                    <Col lg={11}>
                      {supplier?.bank_details?.map((dt, index) => (
                        <Row key={`bankDetail${index}`}>
                          <Col
                            lg={2}
                            className={`${
                              supplier?.bank_details.length > 1 &&
                              index + 1 < supplier?.bank_details.length
                                ? "table-border-line py-4"
                                : "py-4"
                            }`}
                          >
                            <div className="supplier-td-head">
                              Account Holder Name
                            </div>
                            <div className="supplier-td-body">
                              {dt?.ac_holder_name}
                            </div>
                          </Col>
                          <Col
                            lg={2}
                            className={`${
                              supplier?.bank_details.length > 1 &&
                              index + 1 < supplier?.bank_details.length
                                ? "table-border-line py-4"
                                : "py-4"
                            }`}
                          >
                            <div className="supplier-td-head">
                              Account Number
                            </div>
                            <div className="supplier-td-body">
                              {dt?.ac_number}
                            </div>
                          </Col>
                          <Col
                            lg={2}
                            className={`${
                              supplier?.bank_details.length > 1 &&
                              index + 1 < supplier?.bank_details.length
                                ? "table-border-line py-4"
                                : "py-4"
                            }`}
                          >
                            <div className="supplier-td-head">Bank Name</div>
                            <div className="supplier-td-body">
                              {dt?.banks?.label}
                            </div>
                          </Col>
                          <Col
                            lg={2}
                            className={`${
                              supplier?.bank_details.length > 1 &&
                              index + 1 < supplier?.bank_details.length
                                ? "table-border-line py-4"
                                : "py-4"
                            }`}
                          >
                            <div className="supplier-td-head">Branch Name</div>
                            <div className="supplier-td-body">
                              {dt?.branch_name}
                            </div>
                          </Col>
                          <Col
                            lg={2}
                            className={`${
                              supplier?.bank_details.length > 1 &&
                              index + 1 < supplier?.bank_details.length
                                ? "table-border-line py-4"
                                : "py-4"
                            }`}
                          >
                            <div className="supplier-td-head">IBAN</div>
                            <div className="supplier-td-body">{dt?.iban}</div>
                          </Col>
                          <Col
                            lg={2}
                            className={`${
                              supplier?.bank_details.length > 1 &&
                              index + 1 < supplier?.bank_details.length
                                ? "table-border-line py-4"
                                : "py-4"
                            }`}
                          >
                            <div className="supplier-td-head">
                              SWIFT/BIC Code
                            </div>
                            <div className="supplier-td-body">
                              {dt?.swift_code}
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </>
              )}
            </Card>
          </Container>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader className="custom-modal-header">
          <div className="d-flex justify-content-between">
            <h5>{"View File"}</h5>
            <img
              src={closeIcon}
              alt=""
              onClick={() => setModal(false)}
              width="10"
              role="button"
            />
          </div>
        </ModalHeader>

        <ModalBody className="modal-iframe">
          <iframe
            title="File Viewer"
            src={`${imgUrl}${fileView}`}
            className="iframe-view"
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default ViewSupplier
