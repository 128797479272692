import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  UncontrolledAccordion,
} from "reactstrap"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toastError, toastSuccess } from "../../../helpers/msg_helper"
import {
  UFDate,
  UFEditor,
  UFInput,
} from "../../../components/Common/Fields/Input"
import Country from "../../../components/Common/chooseFields/country"
import LoadingButton from "../../../components/Common/LoadingButton"
import Department from "../../../components/Common/chooseFields/department"
import ConfirmationAfter from "../../../components/Common/chooseFields/confirmationAfter"
import CostCenter from "../../../components/Common/chooseFields/costCenter"
import EmploymentType from "../../../components/Common/chooseFields/employmentType"
import Gender from "../../../components/Common/chooseFields/gender"
import Marital from "../../../components/Common/chooseFields/maritalStatus"
import Languages from "../../../components/Common/chooseFields/languages"
import BloodGroup from "../../../components/Common/chooseFields/bloodGroup"
import Religion from "../../../components/Common/chooseFields/religion"
import EmployeeType from "../../../components/Common/chooseFields/employeeType"
import Sponsored from "../../../components/Common/chooseFields/sponsored"
import EmployeeGrade from "../../../components/Common/chooseFields/empGrade"
import {
  fetchEmployee,
  postHrmsEmployeeControl,
} from "../../../services/Hrms/hrms"
import EducationalDegree from "../../../components/Common/chooseFields/eduDegree"
import EducationalDepartment from "../../../components/Common/chooseFields/eduDepartment"
import SingleFileInput from "../../../components/Common/Fields/singleFileInput"
import SalaryType from "../../../components/Common/chooseFields/salaryType"
import CampCode from "../../../components/Common/chooseFields/campcode"
import moment from "moment"
import Document from "../../../components/Common/chooseFields/document"
import PhoneInputField from "../../../components/Common/Fields/phoneInput"
import StateList from "../../../components/Common/chooseFields/stateList"
import BankList from "../../../components/Common/chooseFields/bankList"
import Designation from "../../../components/Common/chooseFields/designation"
import RelationshipList from "../../../components/Common/chooseFields/relationship"
import Vaccine from "../../../components/Common/chooseFields/vaccine"
import BoosterList from "../../../components/Common/chooseFields/booster"
import UsersList from "../../../components/Common/chooseFields/userList"
import lightTrash from "../../../assets/icons/light-trash.svg"
import PayrollDrop from "../../../components/Common/chooseFields/payrollDrop"
import { payrollList } from "../../../services/Settings/dropdown"
import BenefitsLi from "../../../components/Common/chooseFields/benefitsLi"
import EntitlementLi from "../../../components/Common/chooseFields/entitlementLi"
import FinanceGroupType from "../../../components/Common/chooseFields/financeGroupType"

const EditEmployee = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [payrollValue, setPayrollValue] = useState([])
  const [documentFile, setDocumentFile] = useState()
  const [educationFile, setEducationFile] = useState()
  const [profile, setProfile] = useState()
  const [userLoginCheck, setIsUserLoginCheck] = useState(false)
  const [additionPayroll, setAdditionPayroll] = useState([])
  const [deductionPayroll, setDeductionPayroll] = useState([])

  const schema = yup.object().shape({
    first_name: yup.string().required("First Name is Required"),
    // ...(id && {
    //   employee_code: yup.string().required("Employee Code is Required"),
    // }),
    // middle_name: yup.string(),
    // last_name: yup.string().required("Last Name is Required"),
    // department_id: yup.mixed().required("Department is Required"),
    // account_group_id: yup.mixed().required("Nature of Account is Required"),
    // designation_id: yup.mixed().required("Designation is Required"),
    // grade_id: yup.mixed().required("Grade is Required"),
    // confirmation_after: yup.mixed().required("confirmation After is Required"),
    // employment_type: yup.mixed().required("Employment Type is Required"),
    official_email_id: yup
      .string()
      .email("Must be a valid Email")
      .required("Official Email ID is Required"),
    // official_contact_number: yup
    //   .string()
    //   // .matches(/^([+]?)?[0-9]+$|^$/, "Contact Number must be only digits")
    //   .max(15, "Maximum limit is 15")
    //   .required("Official Contact Number is Required"),
    // date_of_birth: yup.mixed().required("Date of Birth is Required"),
    // age: yup.string().required("Age is Required"),
    // gender_id: yup.mixed().required("Gender is Required"),
    // marital_status_id: yup.mixed().required("Marital Status is Required"),
    // languages_known_id: yup.mixed().required("languages known is Required"),
    // blood_group_id: yup.mixed().required("Blood Group is Required"),
    // religion_id: yup.mixed().required("Religion is Required"),
    // country_id: yup.mixed().required("Country is Required"),
    // state: yup.mixed().required("State is Required"),
    // employee_type_id: yup.mixed().required("Employee Type is Required"),
    // sponsored_by_id: yup.mixed().required("Sponsored By is Required"),
    // current_job_id: yup.mixed().required("Current Job  is Required"),
    // date_of_join: yup.mixed().required("Date of Join is Required"),
    // emergency_no: yup.string().required("Emergency Number is Required"),
    // personal_email_id: yup
    //   .string()
    //   .email("Must be a valid Email")
    //   .required("Personal Email is Required"),
    personal_contact_number: yup
      .string()
      .matches(/^([+]?)?[0-9]+$|^$/, "Contact Number must be only digits")
      .max(15, "Maximum limit is 15")
      .required("Personal Contact Number is Required"),
    // camp_code_id: yup.mixed().required("Camp Code is Required"),
    // current_address: yup.string().required("Current Address is Required"),
    permanent_address: yup.string().required("Permanent Address is Required"),
    // salary_type_id: yup.mixed().required("Salary Type is Required"),
    // total_salary: yup
    //   .string()
    //   .matches(/^([+]?)?[0-9]+$|^$/, "Salary must be only digits")
    //   .required("Salary is Required"),
    // account_holder_name: yup
    //   .string()
    //   .required("Account Holder Name is Required"),
    // account_number: yup
    //   .string()
    //   .matches(/^([+]?)?[0-9]+$|^$/, "Account Number must be only digits")
    //   .required("Account Number is Required"),
    // bank_name: yup.mixed().required("Bank Name is Required"),
    // branch_name: yup.string().required("Branch Name is Required"),
    // iban: yup.string().required("IBAN is Required"),
    // swift_bic_code: yup.string().required("SWIFT/BIC Code is Required"),
    // photo: yup.mixed().required("Photo is required"),
    // documents: yup.array().of(
    //   yup.object().shape({
    //     document_title: yup.mixed().required("Document Title is Required"),
    //     document_no: yup.string().required("Document Number is required"),
    //     expiry_date: yup.string().required("Expiry Date is required"),
    //     // certificates: yup.mixed().required("certificates is required"),
    //   })
    // ),
    // education_details: yup.array().of(
    //   yup.object().shape({
    //     degree_department_id: yup
    //       .mixed()
    //       .required("Degree Department is Required"),
    //     degree_id: yup.mixed().required("Degree is required"),
    //     // certificates: yup.mixed().required("Certificates is required"),
    //   })
    // ),
    // emergency_contacts: yup.array().of(
    //   yup.object().shape({
    //     relationship_id: yup.mixed().required("Relationship is Required"),
    //     name: yup.string().required("Name is Required"),
    //     email: yup
    //       .string()
    //       .email("Must be a valid Email")
    //       .required("Email ID is Required"),
    //     address: yup.string().required("Address is Required"),
    //     phone: yup
    //       .string()
    //       .matches(/^([+]?)?[0-9]+$|^$/, "Phone Number must be only digits")
    //       .max(15, "Maximum limit is 15")
    //       .required("Phone Number is Required"),
    //   })
    // ),
    // medical_details: yup.array().of(
    //   yup.object().shape({
    //     vaccine_id: yup.mixed().required("Vaccine Name is Required"),
    //     booster_id: yup.mixed(),
    //     date: yup.string().required("Vaccinated on is required"),
    //   })
    // ),
    // payroll_master: yup
    //   .array()
    //   .of(
    //     yup.object().shape({
    //       percentage: yup
    //         .string()
    //         .matches(/^([+]?)?[0-9]+$|^$/, "Percentage must be only digits")
    //         .required("Percentage is Required"),
    //     })
    //   ),
    // .test(
    //   "total-percentage",
    //   "Sum of all percentages should not exceed 100",
    //   function (value) {
    //     const totalPercentage = (value || []).reduce((sum, item) => {
    //       const percentage = parseFloat(item.percentage || 0)

    //       return sum + (isNaN(percentage) ? 0 : percentage)
    //     }, 0)

    //     console.log(`totalPercentage`, totalPercentage != 100, totalPercentage);

    //     return totalPercentage === 100
    //   }
    // ),
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      employee_code: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      department_id: null,
      account_group_id: null,
      designation_id: null,
      grade_id: null,
      confirmation_after: null,
      // employment_type: null,
      official_email_id: "",
      official_contact_number: "",
      age: "",
      date_of_birth: null,
      gender_id: null,
      marital_status_id: null,
      languages_known_id: null,
      blood_group_id: null,
      religion_id: null,
      country_id: null,
      state: null,
      employee_type_id: null,
      date_of_join: null,
      emergency_no: "",
      personal_email_id: "",
      personal_contact_number: "",
      current_address: "",
      permanent_address: "",
      camp_code_id: null,
      salary_type_id: null,
      total_salary: "",
      account_holder_name: "",
      account_number: "",
      bank_name: null,
      branch_name: "",
      iban: "",
      swift_bic_code: "",
      photo: "",
      documents: [
        {
          document_title: null,
          document_no: "",
          certificates: null,
          expiry_date: "",
        },
      ],
      emergency_contacts: [
        {
          name: "",
          relationship_id: null,
          phone: "",
          email: "",
          address: "",
        },
      ],
      medical_details: [
        {
          vaccine_id: null,
          booster_id: null,
          date: "",
        },
      ],
      education_details: [
        {
          degree_department_id: null,
          degree_id: null,
          certificates: null,
        },
      ],
    },
  })
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: true,
  })

  const handleState = () => {
    setValue(`state`, null)
  }

  const {
    fields: payrollFields,
    append: payrollAppend,
    remove: payrollRemove,
  } = useFieldArray({
    control,
    name: "payroll_master",
  })

  const handleRegistration = async data => {
    console.log(`data reg`, data);
    
    try {
      setIsLoading(true)
      const docArr = data?.documents?.map(dt => ({
        document_title: dt?.document_title?.value,
        document_no: dt?.document_no,
        certificates: dt?.certificates?.[0],
        expiry_date: dt?.expiry_date
          ? moment(dt?.expiry_date).format("YYYY-MM-DD")
          : "",
      }))
      const educationDetails = data?.education_details?.map((dt, index) => ({
        degree_department_id: dt?.degree_department_id?.value,
        degree_id: dt?.degree_id?.value,
        certificates: dt?.certificates?.[0],
      }))
      const emergencyDetails = data?.emergency_contacts?.map((dt, index) => ({
        name: dt?.name,
        relationship_id: dt?.relationship_id?.value,
        phone: dt?.phone,
        email: dt?.email,
        address: dt?.address,
      }))
      const medicalDetails = data?.medical_details?.map((dt, index) => ({
        vaccine_id: dt?.vaccine_id?.value,
        booster_id: dt?.booster_id?.value,
        date: dt?.date ? moment(dt?.date).format("YYYY-MM-DD") : "",
      }))
      let updatedAdditionArray = (data?.percentageAddition || []).map(
        (item, index) => {
          // Get the value from each object
          let value = Object.values(item)[0]

          let type_id = additionPayroll[index]?.id

          // Replace empty value with 0, otherwise keep the original value
          return {
            percentage: value,
            total_salary: data?.total_salary,
            type_id: type_id,
          }
        }
      )

      let updatedDeductionArray = (data?.percentageDeduction || []).map(
        (item, index) => {
          // Get the value from each object
          let value = Object.values(item)[0]

          let type_id = deductionPayroll[index]?.id

          // Replace empty value with 0, otherwise keep the original value
          return {
            percentage: value,
            total_salary: data?.total_salary,
            type_id: type_id,
          }
        }
      )

      console.log(`updatedDeductionArray`, updatedDeductionArray);
      

      // Combine both arrays
      const payroll = [...updatedAdditionArray, ...updatedDeductionArray]

      console.log(`payroll`, payroll) ;
      

      // if (data?.payroll_master?.length) {
      //   data?.payroll_master?.forEach(dt =>
      //     payroll.push({
      //       total_salary: data?.total_salary,
      //       percentage: dt?.percentage,
      //       type_id: dt?.payroll_components_id?.id,
      //     })
      //   )
      // }
      // if (watchGrade?.grade_payroll?.length) {
      //   watchGrade?.grade_payroll?.forEach(dt =>
      //     payroll.push({
      //       salary_type_id: data?.salary_type_id?.value,
      //       total_salary: data?.total_salary,
      //       type_id: dt?.id,
      //       percentage: data?.percentage?.find(
      //         item => item[dt?.payroll_components_id?.name]
      //       )?.[dt?.payroll_components_id?.name],
      //     })
      //   )
      // } else {
      //   payrollValue?.forEach(dt =>
      //     payroll.push({
      //       salary_type_id: data?.salary_type_id?.value,
      //       total_salary: data?.total_salary,
      //       type_id: dt?.type_id?.id,
      //       percentage: data?.percentage?.find(
      //         item => item[dt?.type_id?.payroll_components_id?.name]
      //       )?.[dt?.type_id?.payroll_components_id?.name],
      //       id: dt?.id,
      //     })
      //   )
      // }

      // const payrollPercentage = payroll.reduce(
      //   (total, item) => total + parseFloat(item.percentage),
      //   0
      // )
      const languages = data?.languages_known_id?.map(dt => dt?.value)
      const benefitsArr = data?.benefits?.map(dt => dt?.value)
      const entitlementsArr = data?.entitlement?.map(dt => dt?.value)
      // if (payrollPercentage == 100) {
      const updateDepartment = await postHrmsEmployeeControl({
        ...data,
        department_id: data?.department_id?.value,
        account_group_id: data?.account_group_id?.value,
        designation_id: data?.designation_id?.value,
        grade_id: data?.grade_id?.value,
        confirmation_after: data?.confirmation_after?.value,
        // employment_type: data?.employment_type?.value,
        gender_id: data?.gender_id?.value,
        marital_status_id: data?.marital_status_id?.value,
        languages_known_id: languages,
        benefits: benefitsArr,
        entitlements: entitlementsArr,
        blood_group_id: data?.blood_group_id?.value,
        religion_id: data?.religion_id?.value,
        country_id: data?.country_id?.value,
        state: data?.state?.value,
        employee_type_id: data?.employee_type_id?.value,
        sponsored_by_id: data?.sponsored_by_id?.value,
        bank_name: data?.bank_name?.value,
        is_user: userLoginCheck ? 1 : 0,
        reporting_to: data?.reporting_to?.value,
        bio: data?.bio,

        camp_code_id: data?.camp_code_id?.value,
        cost_center_id: "",
        photo: data?.photo?.[0],
        ...(docArr?.length && { documents: docArr }),
        education_details: educationDetails,
        ...(emergencyDetails?.length && {
          emergency_contacts: emergencyDetails,
        }),
        ...(medicalDetails?.length && { medical_details: medicalDetails }),
        date_of_join: data?.date_of_join
          ? moment(data?.date_of_join).format("YYYY-MM-DD")
          : "",
        date_of_birth: data?.date_of_birth
          ? moment(data?.date_of_birth).format("YYYY-MM-DD")
          : "",
        employee_payrolls: payroll,
      })
      navigate("/hrms-employee")
      toastSuccess(updateDepartment?.message)
      // } else {
      //   toastError("Payroll percentage Must match 100")
      // }
    } catch (err) {
      toastError(err?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const {
    fields: educationalFields,
    append: educationalAppend,
    remove: educationalRemove,
  } = useFieldArray({
    control,
    name: "education_details",
  })
  const {
    fields: documentFields,
    append: documentAppend,
    remove: documentRemove,
  } = useFieldArray({
    control,
    name: "documents",
  })
  const {
    fields: emergencyFields,
    append: emergencyAppend,
    remove: emergencyRemove,
  } = useFieldArray({
    control,
    name: "emergency_contacts",
  })
  const {
    fields: medicalFields,
    append: medicalAppend,
    remove: medicalRemove,
  } = useFieldArray({
    control,
    name: "medical_details",
  })

  const watchGrade = watch("grade_id")

  const handleGradeChange = e => {
    setPayrollValue([])
    const percent = e.grade_payroll.map(dt => ({
      [dt.payroll_components_id.name]: dt.percentage,
    }))
    setValue("percentage", percent)
  }
  const handleChange = (name, file) => {
    setValue(name, file)
  }

  useEffect(() => {
    const handleFetchEmployee = async () => {
      const response = await fetchEmployee({ id: id })

      const educationalArray = response?.data?.education_details?.map(dt => ({
        degree_department_id: dt?.degree_department?.id
          ? {
              value: dt?.degree_department?.id,
              label: dt?.degree_department?.description,
            }
          : null,
        degree_id: dt?.degree?.id
          ? {
              value: dt?.degree?.id,
              label: dt?.degree?.degree,
            }
          : null,
        certificates: dt?.certificates,
      }))
      const documentsArray = response?.data?.employee_documents?.map(dt => ({
        document_title: dt?.document_title?.id
          ? {
              value: dt?.document_title?.id,
              label: dt?.document_title?.name,
            }
          : null,
        document_no: dt?.document_no,
        certificates: dt?.certificates,
        expiry_date: dt?.expiry_date,
      }))
      const emergencyContactArray = response?.data?.emergencycontacts?.map(
        dt => ({
          name: dt?.name,
          relationship_id: dt?.relationships?.id
            ? {
                label: dt?.relationships?.name,
                value: dt?.relationships?.id,
              }
            : null,
          phone: dt?.phone,
          email: dt?.email,
          address: dt?.address,
        })
      )
      const additionArr = response?.data?.employee_payroll.filter(
        item => item?.payrollcomponent.is_deduction === "0"
      )
      const deductionArr = response?.data?.employee_payroll.filter(
        item => item?.payrollcomponent.is_deduction === "1"
      )

      const payrollAdditionArr = additionArr?.map(dt => ({
        percentage: dt?.percentage,
      }))
      const payrollDeductionArr = deductionArr?.map(dt => ({
        percentage: dt?.percentage,
      }))
      const medicalArray = response?.data?.medical_details?.map(dt => ({
        vaccine_id: dt?.vaccin?.id
          ? { label: dt?.vaccin?.name, value: dt?.vaccin?.id }
          : null,
        date: dt?.date,
        booster_id: dt?.booster?.id
          ? { label: dt?.booster?.name, value: dt?.booster?.id }
          : null,
      }))
      const percent = response?.data.employee_payroll?.length
        ? response?.data.employee_payroll.map(dt => ({
            [dt.type_id?.payroll_components_id.name]: dt?.type_id?.percentage,
          }))
        : []
      const languages =
        response?.data?.personal_details?.languages?.map(dt => ({
          label: dt?.name,
          value: dt?.id,
        })) || []
      const benefitsArr =
        response?.data?.benefit?.map(dt => ({
          label: dt?.name,
          value: dt?.id,
        })) || []
      const entitlementsArr =
        response?.data?.entitlements?.map(dt => ({
          label: dt?.name,
          value: dt?.id,
        })) || []
      reset({
        ...response?.data?.personal_details,
        ...response?.data,
        emergency_no:
          response?.data?.personal_details?.emergency_no != null
            ? String(response?.data?.personal_details?.emergency_no)
            : "971",
        official_contact_number:
          response?.data?.official_contact_number != null
            ? String(response?.data?.official_contact_number)
            : "971",
        benefits: benefitsArr,
        entitlement: entitlementsArr,
        department_id: response?.data?.department?.id
          ? {
              label: response?.data?.department?.name,
              value: response?.data?.department?.id,
            }
          : null,
        account_group_id: response?.data?.account_group_id?.value
          ? {
              label: response?.data?.account_group_id?.label,
              value: response?.data?.account_group_id?.value,
            }
          : null,
        bio: response?.data?.bio,
        reporting_to: response?.data?.reporting?.id
          ? {
              label: response?.data?.reporting?.name,
              value: response?.data?.reporting?.id,
            }
          : null,
        designation_id: response?.data?.designation?.id
          ? {
              label: response?.data?.designation?.name,
              value: response?.data?.designation?.id,
            }
          : null,
        grade_id: response?.data?.grade?.id
          ? {
              label: response?.data?.grade?.grade,
              value: response?.data?.grade?.id,
            }
          : null,
        bank_name: response?.data?.banks != null
          ? {
              label: response?.data?.banks?.label,
              value: response?.data?.banks?.id,
            }
          : null,
        confirmation_after: response?.data?.confirmation_after != null
          ? {
              label: response?.data?.confirmation_after?.label,
              value: response?.data?.confirmation_after?.id,
            }
          : null,
        // employment_type: {
        //   label: response?.data?.employment_type?.label,
        //   value: response?.data?.employment_type?.value,
        // },
        photo: response?.data?.photo,
        gender_id: response?.data?.gender_type != null
          ? {
              label: response?.data?.gender_type?.label,
              value: response?.data?.gender_type?.id,
            }
          : null,
        marital_status_id: response?.data?.marital_status_id != null
          ? {
              label: response?.data?.marital_status_id?.label,
              value: response?.data?.marital_status_id?.id,
            }
          : null,
        languages_known_id: languages,
        blood_group_id: response?.data?.blood_group != null
          ? {
              label: response?.data?.blood_group?.label,
              value: response?.data?.blood_group?.id,
            }
          : null,
        religion_id: response?.data?.personal_details?.religion_id?.id
          ? {
              label: response?.data?.personal_details?.religion_id?.religion,
              value: response?.data?.personal_details?.religion_id?.id,
            }
          : null,
        country_id: response?.data?.personal_details?.country_id?.id
          ? {
              label: response?.data?.personal_details?.country_id?.name,
              value: response?.data?.personal_details?.country_id?.id,
            }
          : null,
        state: response?.data?.personal_details?.state?.id
          ? {
              label: response?.data?.personal_details?.state?.name,
              value: response?.data?.personal_details?.state?.id,
            }
          : null,
        employee_type_id: response?.data?.personal_details?.employee_type_id?.id
          ? {
              label:
                response?.data?.personal_details?.employee_type_id
                  ?.employee_type,
              value: response?.data?.personal_details?.employee_type_id?.id,
            }
          : null,
        sponsored_by_id: response?.data?.personal_details?.sponsored_by_id?.id
          ? {
              label:
                response?.data?.personal_details?.sponsored_by_id?.sponsored_by,
              value: response?.data?.personal_details?.sponsored_by_id?.id,
            }
          : null,
        camp_code_id: response?.data?.personal_details?.camp_code_id?.id
          ? {
              label: response?.data?.personal_details?.camp_code_id?.camp_code,
              value: response?.data?.personal_details?.camp_code_id?.id,
            }
          : null,
        salary_type_id: response?.data?.salary_type?.id
          ? {
              label: response?.data?.salary_type?.label,
              value: response?.data?.salary_type?.id,
            }
          : null,
        education_details: educationalArray,
        documents: documentsArray,
        emergency_contacts: emergencyContactArray,
        medical_details: medicalArray,
        percentage: percent,
        percentageAddition: payrollAdditionArr,
        percentageDeduction: payrollDeductionArr,
      })
      setPayrollValue(response?.data?.employee_payroll)
      setEducationFile(response?.data?.education_details)
      setDocumentFile(response?.data?.employee_documents)
      setProfile(response?.data?.photo)
      setIsUserLoginCheck(response?.data?.is_user)
    }
    if (id) {
      handleFetchEmployee()
    }
  }, [id])
  useEffect(() => {
    const payrollLists = async () => {
      const payrollResponce = await payrollList()
      const additionArr = payrollResponce?.data.filter(
        item => item.is_deduction === "0"
      )
      const deductionArr = payrollResponce?.data.filter(
        item => item.is_deduction === "1"
      )
      setAdditionPayroll(additionArr)
      setDeductionPayroll(deductionArr)
    }
    if (payrollValue) {
      payrollLists()
    }
  }, [payrollValue])

  console.log(`payrollValue`, payrollValue)

  const handleUserLoginCheck = dt => {
    setIsUserLoginCheck(!dt)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <FormProvider {...methods}>
            <Form
              id={`employee-form`}
              onSubmit={handleSubmit(handleRegistration)}
            >
              <div className="sticky-top w-100">
                <div className="d-flex justify-content-between me-2">
                  <Breadcrumbs
                    title="HRMS"
                    breadcrumbObject={[
                      { title: "HRMS", link: "/hrms" },
                      { title: "Employee", link: "/hrms-employee" },
                      {
                        title: id ? "Edit Employee" : "Add Employee",
                        link: "",
                      },
                    ]}
                  />
                  <LoadingButton
                    form={`employee-form`}
                    color={"primary"}
                    loading={isLoading}
                    disabled={isLoading}
                    className={"px-2 mb-3 btn btn-primary"}
                    type="submit"
                  >
                    {id ? "Update" : "Save"}
                  </LoadingButton>
                </div>
              </div>
              <Container fluid className="ps-lg-0">
                <Card className="invoice-main-card p-3 pt-4">
                  <Row className="">
                    <Col sm={12}>
                      <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            <h6> Basic Details</h6>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <Row className="mb-4 mt-2">
                              {id && (
                                <Col sm={3}>
                                  <FormGroup className="mb-4">
                                    <Label>Employee Code</Label>
                                    <UFInput
                                      control={control}
                                      placeholder=""
                                      name={"employee_code"}
                                      invalid={!!errors.employee_code}
                                    />
                                    <FormFeedback invalid>
                                      {errors.employee_code?.message}
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              )}
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    First Name
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the First Name"
                                    name={"first_name"}
                                    invalid={!!errors.first_name}
                                  />
                                  <FormFeedback invalid>
                                    {errors.first_name?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Middle Name</Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Middle Name"
                                    name={"middle_name"}
                                    invalid={!!errors.middle_name}
                                  />
                                  <FormFeedback invalid>
                                    {errors.middle_name?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Last Name</Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Last Name"
                                    name={"last_name"}
                                    invalid={!!errors.last_name}
                                  />
                                  <FormFeedback invalid>
                                    {errors.last_name?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>

                              <Col sm={3}>
                                <FormGroup>
                                  <Label>Nature Of Account</Label>
                                  <FinanceGroupType
                                    control={control}
                                    placeholder="select"
                                    name={"account_group_id"}
                                    invalid={!!errors.account_group_id}
                                    // target={"body"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.account_group_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>

                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Department</Label>
                                  <Department
                                    control={control}
                                    placeholder="Select the Department"
                                    name={"department_id"}
                                    invalid={!!errors.department_id}
                                    target={"body"}
                                    param="department"
                                  />
                                  <FormFeedback invalid>
                                    {errors.department_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Designation</Label>
                                  {/* <Department
                                    control={control}
                                    placeholder="Select the Designation"
                                    name={"designation_id"}
                                    invalid={!!errors.designation_id}
                                    target={"body"}
                                    param="designation"
                                  /> */}
                                  <Designation
                                    control={control}
                                    placeholder="Select the Designation"
                                    name={"designation_id"}
                                    invalid={!!errors.designation_id}
                                    target={"body"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.designation_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              {/* <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Cost Center
                                    <span className="mandatory"></span>
                                  </Label>
                                  <CostCenter
                                    control={control}
                                    placeholder="Select the Cost Center"
                                    name={"cost_center_id"}
                                    invalid={!!errors.cost_center_id}
                                    target={"body"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.cost_center_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col> */}
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Grade</Label>
                                  <EmployeeGrade
                                    control={control}
                                    placeholder="Select the Grade"
                                    name={"grade_id"}
                                    invalid={!!errors.grade_id}
                                    target={"body"}
                                    onSelectChange={e => handleGradeChange(e)}
                                  />
                                  <FormFeedback invalid>
                                    {errors.grade_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Probation Period</Label>
                                  <ConfirmationAfter
                                    control={control}
                                    placeholder="Select "
                                    name={"confirmation_after"}
                                    invalid={!!errors.confirmation_after}
                                    target={"body"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.confirmation_after?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              {/* <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Contract
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <EmploymentType
                                    control={control}
                                    placeholder="Select "
                                    name={"employment_type"}
                                    invalid={!!errors.employment_type}
                                    target={"body"}
                                  />

                                  <FormFeedback invalid>
                                    {errors.employment_type?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col> */}
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Email ID
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Email ID"
                                    name={"official_email_id"}
                                    invalid={!!errors.official_email_id}
                                  />
                                  <FormFeedback invalid>
                                    {errors.official_email_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Official Contact Number</Label>
                                  <PhoneInputField
                                    control={control}
                                    name={"official_contact_number"}
                                    invalid={!!errors.official_contact_number}
                                  />
                                  <FormFeedback invalid>
                                    {errors.official_contact_number?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col lg="3" xs={12} md={5} sm={12}>
                                <FormGroup className="mb-4">
                                  <Label>Profile Photo</Label>
                                  <SingleFileInput
                                    control={control}
                                    accept=".png,.jpg,.jpeg,.svg"
                                    name={`photo`}
                                    label="Upload File"
                                    onlyIcon={true}
                                    tooltip="Cerificate Upload"
                                    handleChange={handleChange}
                                    downloadPath={profile}
                                  />
                                  <FormFeedback invalid>
                                    {errors.photo?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col
                                lg={12}
                                onClick={() =>
                                  handleUserLoginCheck(userLoginCheck)
                                }
                              >
                                <FormGroup>
                                  <input
                                    type="checkbox"
                                    className="ever-rise-checkbox"
                                    name={"is_user"}
                                    checked={userLoginCheck}
                                  />
                                  <Label className="ms-2">
                                    Is user can access
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                        <hr className="m-0" />
                        {userLoginCheck == true && (
                          <>
                            <AccordionItem>
                              <AccordionHeader targetId="10">
                                <h6> User Details</h6>
                              </AccordionHeader>
                              <AccordionBody accordionId="10">
                                <Row className="mb-4 mt-2">
                                  <Col sm={3}>
                                    <FormGroup className="mb-4">
                                      <Label>Reporting To</Label>
                                      <UsersList
                                        control={control}
                                        name={"reporting_to"}
                                        placeholder={"Name"}
                                        target={"body"}
                                      />
                                      <FormFeedback invalid>
                                        {errors.reporting_to?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={3}>
                                    <FormGroup className="mb-4">
                                      <Label>Bio</Label>
                                      <UFEditor
                                        control={control}
                                        placeholder="Enter the Bio"
                                        rows="5"
                                        name={"bio"}
                                        invalid={!!errors.bio}
                                        className={"pb-0"}
                                      />
                                      <FormFeedback invalid>
                                        {errors.bio?.message}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                            <hr className="m-0" />
                          </>
                        )}
                        <AccordionItem>
                          <AccordionHeader targetId="2">
                            <h6> Personal Details</h6>
                          </AccordionHeader>
                          <AccordionBody accordionId="2">
                            <Row className="mb-4 mt-2">
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Date Of Birth</Label>
                                  <UFDate
                                    control={control}
                                    name={"date_of_birth"}
                                    invalid={!!errors.date_of_birth}
                                  />
                                  <FormFeedback invalid>
                                    {errors.date_of_birth?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Gender</Label>
                                  <Gender
                                    control={control}
                                    placeholder="select"
                                    name={"gender_id"}
                                    invalid={!!errors.gender_id}
                                    target={"body"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.gender_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Marital Status</Label>
                                  <Marital
                                    control={control}
                                    placeholder="select"
                                    name={"marital_status_id"}
                                    invalid={!!errors.marital_status_id}
                                    target={"body"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.marital_status_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Languages Known</Label>
                                  <Languages
                                    control={control}
                                    placeholder="select"
                                    name={"languages_known_id"}
                                    invalid={!!errors.languages_known_id}
                                    target={"body"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.languages_known_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Blood Group</Label>
                                  <BloodGroup
                                    control={control}
                                    placeholder="Select"
                                    name={"blood_group_id"}
                                    invalid={!!errors.blood_group_id}
                                    target={"body"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.blood_group_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Religion</Label>
                                  <Religion
                                    control={control}
                                    placeholder="select"
                                    name={"religion_id"}
                                    invalid={!!errors.religion_id}
                                    target={"body"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.religion_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Country</Label>
                                  <Country
                                    control={control}
                                    placeholder="select"
                                    name={"country_id"}
                                    invalid={!!errors.country_id}
                                    target={"body"}
                                    onSelectChange={() => handleState()}
                                  />
                                  <FormFeedback invalid>
                                    {errors.country_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>State</Label>
                                  <StateList
                                    control={control}
                                    placeholder="select"
                                    name={"state"}
                                    selectedCountry={watch("country_id")}
                                  />
                                  <FormFeedback invalid>
                                    {errors.state?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Employee Type</Label>
                                  <EmployeeType
                                    control={control}
                                    placeholder="select"
                                    name={"employee_type_id"}
                                    invalid={!!errors.employee_type_id}
                                    target={"body"}
                                  />

                                  <FormFeedback invalid>
                                    {errors.employee_type_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Sponsored By</Label>
                                  <Sponsored
                                    control={control}
                                    placeholder="select"
                                    name={"sponsored_by_id"}
                                    invalid={!!errors.sponsored_by_id}
                                    target={"body"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.sponsored_by_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>

                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Date of Join</Label>
                                  <UFDate
                                    control={control}
                                    name={"date_of_join"}
                                    invalid={!!errors.date_of_join}
                                  />

                                  <FormFeedback invalid>
                                    {errors.date_of_join?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Emergency No</Label>

                                  <PhoneInputField
                                    control={control}
                                    name={"emergency_no"}
                                    invalid={!!errors.emergency_no}
                                  />
                                  <FormFeedback invalid>
                                    {errors.emergency_no?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Personal Email ID</Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Email ID"
                                    name={"personal_email_id"}
                                    invalid={!!errors.personal_email_id}
                                  />
                                  <FormFeedback invalid>
                                    {errors.personal_email_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Personal Contact Number
                                    <span className="mandatory">*</span>
                                  </Label>

                                  <PhoneInputField
                                    control={control}
                                    name={"personal_contact_number"}
                                    invalid={!!errors.personal_contact_number}
                                  />
                                  <FormFeedback invalid>
                                    {errors.personal_contact_number?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                        <hr className="m-0" />

                        <AccordionItem>
                          <AccordionHeader targetId="3">
                            <h6> Address</h6>
                          </AccordionHeader>
                          <AccordionBody accordionId="3">
                            <Row className="mb-4 mt-2">
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Camp Code</Label>
                                  <CampCode
                                    control={control}
                                    placeholder="select"
                                    name={"camp_code_id"}
                                    invalid={!!errors.camp_code_id}
                                  />
                                  <FormFeedback invalid>
                                    {errors.camp_code_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Current Address</Label>
                                  <UFEditor
                                    control={control}
                                    placeholder="Enter the Current Address"
                                    rows="5"
                                    name={"current_address"}
                                    invalid={!!errors.current_address}
                                    className={"pb-0"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.current_address?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Personal Address
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <UFEditor
                                    control={control}
                                    placeholder="Enter the Personal Address"
                                    rows="5"
                                    name={"permanent_address"}
                                    invalid={!!errors.permanent_address}
                                    className={"pb-0"}
                                  />

                                  <FormFeedback invalid>
                                    {errors.permanent_address?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                        <hr className="m-0" />
                        {!id ? (
                          <>
                            <AccordionItem>
                              <AccordionHeader targetId="4">
                                <h6> Educational Details</h6>
                              </AccordionHeader>
                              <AccordionBody accordionId="4">
                                <Row className="mb-4">
                                  {educationalFields.map((item, index) => (
                                    <Row key={item.id}>
                                      <Col lg="3" xs={12} md={5} sm={12}>
                                        <FormGroup className="mb-4">
                                          <Label>Degree</Label>
                                          <EducationalDegree
                                            control={control}
                                            placeholder="Enter the Degree"
                                            name={`education_details.${index}.degree_id`}
                                            invalid={
                                              !!errors?.education_details?.[
                                                index
                                              ]?.degree_id
                                            }
                                          />
                                          <FormFeedback invalid>
                                            {
                                              errors.education_details?.[index]
                                                ?.degree_id?.message
                                            }
                                          </FormFeedback>
                                        </FormGroup>
                                      </Col>
                                      <Col lg="3" xs={12} md={5} sm={12}>
                                        <FormGroup className="mb-4">
                                          <Label>Department</Label>
                                          <EducationalDepartment
                                            control={control}
                                            placeholder="Enter the Department"
                                            rows="3"
                                            name={`education_details.${index}.degree_department_id`}
                                            invalid={
                                              !!errors?.education_details?.[
                                                index
                                              ]?.degree_department_id
                                            }
                                          />
                                          <FormFeedback invalid>
                                            {
                                              errors.education_details?.[index]
                                                ?.degree_department_id?.message
                                            }
                                          </FormFeedback>
                                        </FormGroup>
                                      </Col>
                                      <Col lg="3" xs={12} md={5} sm={12}>
                                        <FormGroup className="mb-4">
                                          <Label>Cerificate</Label>
                                          <SingleFileInput
                                            control={control}
                                            accept=".png,.jpg,.jpeg,.svg,.pdf"
                                            name={`education_details.${index}.certificates`}
                                            label="Upload File"
                                            onlyIcon={true}
                                            tooltip="Cerificate Upload"
                                            handleChange={handleChange}
                                            downloadPath={
                                              educationFile?.[index]
                                                ?.certificates
                                            }
                                          />
                                          <FormFeedback invalid>
                                            {
                                              errors.education_details?.[index]
                                                ?.certificates?.message
                                            }
                                          </FormFeedback>
                                        </FormGroup>
                                      </Col>
                                      <Col
                                        lg="12"
                                        xs={12}
                                        md={2}
                                        sm={12}
                                        className="d-flex justify-content-start"
                                      >
                                        {educationalFields.length ==
                                        index + 1 ? (
                                          <div>
                                            <Button
                                              color="secondary"
                                              className="addButton my-3 me-3 mt-0"
                                              onClick={() =>
                                                educationalAppend({
                                                  degree_id: "",
                                                  degree_department_id: "",
                                                  certificates: "",
                                                })
                                              }
                                            >
                                              Add Row
                                            </Button>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {educationalFields.length > 1 ? (
                                          <div>
                                            <Button
                                              color="primary"
                                              className="removeButton my-3  mt-0"
                                              onClick={() =>
                                                educationalRemove(index)
                                              }
                                            >
                                              Delete Row
                                            </Button>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                  ))}
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                            <hr className="m-0" />
                          </>
                        ) : (
                          <></>
                        )}
                        <AccordionItem>
                          <AccordionHeader targetId="5">
                            <h6> Payroll</h6>
                          </AccordionHeader>
                          <AccordionBody accordionId="5">
                            <Row className="mb-4 mt-2">
                              {/* <Col sm={4}>
                                <FormGroup className="mb-4">
                                  <Label>
                                    Salary Type
                                    <span className="mandatory">*</span>
                                  </Label>
                                  <SalaryType
                                    control={control}
                                    placeholder="Select Salary Type"
                                    name={"salary_type_id"}
                                    invalid={!!errors.salary_type_id}
                                  />
                                  <FormFeedback invalid>
                                    {errors.salary_type_id?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col> */}
                              <Col sm={4}>
                                <FormGroup className="mb-4">
                                  <Label>Basic salary</Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Total salary"
                                    name={"total_salary"}
                                    invalid={!!errors.total_salary}
                                    className="salary_class"
                                    disabled={id}
                                  />
                                  <FormFeedback invalid>
                                    {errors.total_salary?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              {watch("total_salary") != "" ? (
                                <div>
                                  <Row className="pt-4">
                                    <Col sm={6}>
                                      <div>Addition</div>
                                      {additionPayroll?.map((item, index) => (
                                        <Row>
                                          {/* <Col sm={3}>
                                            <FormGroup className="mb-4">
                                              <Label>{item?.name}</Label>
                                              <p className="form-control">{`${
                                                watch(
                                                  `percentageAddition.${index}.percentage`
                                                )
                                                  ? (
                                                      Number(
                                                        watch("total_salary")
                                                      ) *
                                                      (Number(
                                                        watch(
                                                          `percentageAddition.${index}.percentage`
                                                        )
                                                      ) /
                                                        100)
                                                    ).toFixed(2)
                                                  : 0
                                              }`}</p>
                                            </FormGroup>
                                          </Col> */}
                                          <Col sm={6}>
                                            <FormGroup className="mb-4">
                                              <Label>{item?.name}</Label>
                                              <Row>
                                                <Col lg={10}>
                                                  <UFInput
                                                    control={control}
                                                    placeholder="Amount"
                                                    name={`percentageAddition.${index}.percentage`}
                                                  />
                                                  <input
                                                    type="hidden"
                                                    name={`type_id.${index}`}
                                                    value={`percentageAddition.${index}.${item?.id}`}
                                                  />
                                                </Col>
                                                {/* <Col
                                                  lg={1}
                                                  className="p-0 d-flex align-items-center"
                                                >
                                                  <span>%</span>
                                                </Col> */}
                                              </Row>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      ))}
                                    </Col>
                                    <Col sm={6}>
                                      <div>Deduction</div>
                                      {deductionPayroll?.map((item, index) => (
                                        <Row>
                                          {/* <Col sm={3}>
                                            <FormGroup className="mb-4">
                                              <Label>{item?.name}</Label>
                                              <p className="form-control">{`${
                                                watch(
                                                  `percentageDeduction.${index}.percentage`
                                                )
                                                  ? (
                                                      Number(
                                                        watch("total_salary")
                                                      ) *
                                                      (Number(
                                                        watch(
                                                          `percentageDeduction.${index}.percentage`
                                                        )
                                                      ) /
                                                        100)
                                                    ).toFixed(2)
                                                  : 0
                                              }`}</p>
                                            </FormGroup>
                                          </Col> */}
                                          <Col sm={6}>
                                            <FormGroup className="mb-4">
                                            <Label>{item?.name}</Label>
                                              <Row>
                                                <Col lg={10}>
                                                  <UFInput
                                                    control={control}
                                                    placeholder="Amount"
                                                    name={`percentageDeduction.${index}.percentage`}
                                                  />
                                                </Col>
                                                {/* <UFInput
                                                    control={control}
                                                    type="hidden"
                                                    name={`percentageDeduction.type_id.${index}`}
                                                    value={`${item?.id}`}
                                                  /> */}
                                                {/* <Col
                                                  lg={1}
                                                  className="p-0 d-flex align-items-center"
                                                >
                                                  <span>%</span>
                                                </Col> */}
                                              </Row>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      ))}
                                    </Col>
                                  </Row>
                                  {/* {payrollFields.map((item, index) => (
                                  <Row
                                    className="pt-2"
                                    key={`payroll${item?.id}`}
                                  >
                                    <Col sm={4}>
                                      <PayrollDrop
                                        control={control}
                                        placeholder="select"
                                        name={`payroll_master.${index}.payroll_components_id`}
                                        target={"body"}
                                        selectedPayroll={watch(
                                          `payroll_master`
                                        )}
                                        index={index}
                                        // disabled={index == 0 ? true : false}
                                        invalid={
                                          !!errors?.payroll_master?.[index]
                                            ?.payroll_components_id
                                        }
                                      />
                                      <FormFeedback invalid>
                                        {
                                          errors?.payroll_master?.[index]
                                            ?.payroll_components_id?.message
                                        }
                                      </FormFeedback>
                                    </Col>
                                    <Col sm={2}>
                                      <UFInput
                                        control={control}
                                        placeholder="Percentage"
                                        name={`payroll_master.${index}.percentage`}
                                        invalid={
                                          !!errors?.payroll_master?.[index]
                                            ?.percentage
                                        }
                                      />
                                      <FormFeedback invalid>
                                        {
                                          errors?.payroll_master?.[index]
                                            ?.percentage?.message
                                        }
                                      </FormFeedback>
                                    </Col>
                                    <Col sm={1}>
                                      {index != 0 && (
                                        <img
                                          src={lightTrash}
                                          alt="light-trash"
                                          className="trash-img pointer"
                                          onClick={() => payrollRemove(index)}
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                ))}
                                <Col
                                  lg="12"
                                  xs={12}
                                  md={2}
                                  sm={12}
                                  className="d-flex justify-content-between"
                                >
                                  <Button
                                    color="secondary"
                                    className="addButton mt-2"
                                    onClick={() =>
                                      payrollAppend({
                                        // payroll_component_id: "",
                                        percentage: "",
                                      })
                                    }
                                  >
                                    Add Row
                                  </Button>
                                </Col> */}
                                </div>
                              ) : (
                                <></>
                              )}
                              {/* <Row>
                                {watchGrade?.grade_payroll ? (
                                  watchGrade?.grade_payroll?.map(
                                    (dt, index) => (
                                      <>
                                        <Row>
                                          <Col sm={3}>
                                            <FormGroup className="mb-4">
                                              <Label>
                                                {
                                                  dt?.payroll_components_id
                                                    ?.name
                                                }
                                                <span className="mandatory">
                                                  *
                                                </span>
                                              </Label>
                                              <p className="form-control">{`${(
                                                Number(watch("total_salary")) *
                                                (Number(
                                                  watch(
                                                    `percentage.${index}.${dt?.payroll_components_id?.name}`
                                                  )
                                                ) /
                                                  100)
                                              ).toFixed(2)}`}</p>
                                            </FormGroup>
                                          </Col>
                                          <Col sm={1}>
                                            <FormGroup className="mb-4">
                                              <Label>
                                                <span className="mandatory"></span>
                                              </Label>
                                              <Row>
                                                <Col lg={10}>
                                                  <UFInput
                                                    control={control}
                                                    placeholder={`${dt?.percentage} %`}
                                                    name={`percentage.${index}.${dt?.payroll_components_id?.name}`}
                                                  />
                                                </Col>
                                                <Col
                                                  lg={1}
                                                  className="p-0 d-flex align-items-center"
                                                >
                                                  <span>%</span>
                                                </Col>
                                              </Row>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      </>
                                    )
                                  )
                                ) : payrollValue && id ? (
                                  payrollValue?.map((dt, index) => (
                                    <>
                                      <Row>
                                        <Col sm={3}>
                                          <FormGroup className="mb-4">
                                            <Label>
                                              {
                                                dt?.type_id
                                                  ?.payroll_components_id?.name
                                              }
                                              <span className="mandatory">
                                                *
                                              </span>
                                            </Label>
                                            <p className="form-control">{`${(
                                              Number(watch("total_salary")) *
                                              (Number(
                                                watch(
                                                  `percentage.${index}.${dt?.type_id?.payroll_components_id?.name}`
                                                )
                                              ) /
                                                100)
                                            ).toFixed(2)}`}</p>
                                          </FormGroup>
                                        </Col>
                                        <Col sm={1}>
                                          <FormGroup className="mb-4">
                                            <Label>
                                              <span className="mandatory"></span>
                                            </Label>
                                            <Row>
                                              <Col lg={10}>
                                                <UFInput
                                                  control={control}
                                                  placeholder={`${dt?.type_id?.percentage} %`}
                                                  name={`percentage.${index}.${dt?.type_id?.payroll_components_id?.name}`}
                                                />
                                              </Col>
                                              <Col
                                                lg={1}
                                                className="p-0 d-flex align-items-center"
                                              >
                                                <span>%</span>
                                              </Col>
                                            </Row>
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    </>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </Row> */}
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                        <hr className="m-0" />
                        {!id ? (
                          <>
                            <AccordionItem>
                              <AccordionHeader targetId="6">
                                <h6> Document</h6>
                              </AccordionHeader>
                              <AccordionBody accordionId="6">
                                <Row className="mb-4 mt-2">
                                  {documentFields.map((item, index) => (
                                    <Row key={item.id}>
                                      <Col lg="3" xs={12} md={5} sm={12}>
                                        <FormGroup className="mb-4">
                                          <Label>Document Title</Label>
                                          <Document
                                            control={control}
                                            placeholder="Enter the Document Title"
                                            name={`documents.${index}.document_title`}
                                            invalid={
                                              !!errors?.documents?.[index]
                                                ?.document_title
                                            }
                                          />
                                          <FormFeedback invalid>
                                            {
                                              errors?.documents?.[index]
                                                ?.document_title?.message
                                            }
                                          </FormFeedback>
                                        </FormGroup>
                                      </Col>
                                      <Col lg="3" xs={12} md={5} sm={12}>
                                        <FormGroup className="mb-4">
                                          <Label>Document Number</Label>
                                          <UFInput
                                            control={control}
                                            placeholder="Enter the Document Number"
                                            name={`documents.${index}.document_no`}
                                            invalid={
                                              !!errors?.documents?.[index]
                                                ?.document_no
                                            }
                                          />
                                          <FormFeedback invalid>
                                            {
                                              errors.documents?.[index]
                                                ?.document_no?.message
                                            }
                                          </FormFeedback>
                                        </FormGroup>
                                      </Col>
                                      <Col lg="3" xs={12} md={5} sm={12}>
                                        <FormGroup className="mb-4">
                                          <Label>Expire Date</Label>
                                          <UFDate
                                            control={control}
                                            name={`documents.${index}.expiry_date`}
                                            invalid={
                                              !!errors?.documents?.[index]
                                                ?.expiry_date
                                            }
                                          />
                                          <FormFeedback invalid>
                                            {
                                              errors.documents?.[index]
                                                ?.expiry_date?.message
                                            }
                                          </FormFeedback>
                                        </FormGroup>
                                      </Col>
                                      <Col lg="3" xs={12} md={5} sm={12}>
                                        <FormGroup className="mb-4">
                                          <Label>Upload</Label>
                                          <SingleFileInput
                                            control={control}
                                            accept=".png,.jpg,.jpeg,.svg,.pdf"
                                            name={`documents.${index}.certificates`}
                                            label="Upload File"
                                            onlyIcon={true}
                                            tooltip="Cerificate Upload"
                                            handleChange={handleChange}
                                            downloadPath={
                                              documentFile?.[index]?.filepath
                                            }
                                          />
                                          <FormFeedback invalid>
                                            {
                                              errors.documents?.[index]
                                                ?.certificates?.message
                                            }
                                          </FormFeedback>
                                        </FormGroup>
                                      </Col>

                                      <Col
                                        lg="12"
                                        xs={12}
                                        md={2}
                                        sm={12}
                                        className="d-flex justify-content-end"
                                      >
                                        {documentFields.length == index + 1 ? (
                                          <Button
                                            color="secondary"
                                            className="addButton my-3 mt-0 ms-3"
                                            onClick={() =>
                                              documentAppend({
                                                document_title: "",
                                                document_no: "",
                                                expiry_date: "",
                                                certificates: "",
                                              })
                                            }
                                          >
                                            Add Row
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                        {documentFields.length > 1 ? (
                                          <Button
                                            color="primary"
                                            className="removeButton my-3  mt-0 "
                                            onClick={() =>
                                              documentRemove(index)
                                            }
                                          >
                                            Delete Row
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                  ))}
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                            <hr className="m-0" />
                          </>
                        ) : (
                          <></>
                        )}
                        <AccordionItem>
                          <AccordionHeader targetId="7">
                            <h6> Bank Details</h6>
                          </AccordionHeader>
                          <AccordionBody accordionId="7">
                            <Row className="mb-4 mt-2">
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Account Holder Name</Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Account Holder Name"
                                    name={"account_holder_name"}
                                    invalid={!!errors.account_holder_name}
                                  />
                                  <FormFeedback invalid>
                                    {errors.account_holder_name?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Account Number</Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Account Number"
                                    name={"account_number"}
                                    invalid={!!errors.account_number}
                                  />
                                  <FormFeedback invalid>
                                    {errors.account_number?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Bank Name</Label>
                                  <BankList
                                    control={control}
                                    placeholder="Enter the Bank Name"
                                    name={"bank_name"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.bank_name?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Branch Name</Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the Branch Name"
                                    name={"branch_name"}
                                    invalid={!!errors.branch_name}
                                  />
                                  <FormFeedback invalid>
                                    {errors.branch_name?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>IBAN</Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the IBAN"
                                    name={"iban"}
                                    invalid={!!errors.iban}
                                  />
                                  <FormFeedback invalid>
                                    {errors.iban?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>SWIFT/BIC Code</Label>
                                  <UFInput
                                    control={control}
                                    placeholder="Enter the SWIFT/BIC Code"
                                    name={"swift_bic_code"}
                                    invalid={!!errors.swift_bic_code}
                                  />
                                  <FormFeedback invalid>
                                    {errors.swift_bic_code?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                        <hr className="m-0" />
                        <AccordionItem>
                          <AccordionHeader targetId="8">
                            <h6> Emergency Contact</h6>
                          </AccordionHeader>
                          <AccordionBody accordionId="8">
                            <Row className="mb-4 mt-2">
                              {emergencyFields.map((item, index) => (
                                <Row key={item.id}>
                                  <Col lg="3" xs={12} md={5} sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>Name</Label>
                                      <UFInput
                                        control={control}
                                        placeholder="Enter the Name"
                                        name={`emergency_contacts.${index}.name`}
                                        invalid={
                                          !!errors?.emergency_contacts?.[index]
                                            ?.name
                                        }
                                      />
                                      <FormFeedback invalid>
                                        {
                                          errors.emergency_contacts?.[index]
                                            ?.name?.message
                                        }
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="3" xs={12} md={5} sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>Relationship</Label>
                                      <RelationshipList
                                        control={control}
                                        placeholder="Select"
                                        name={`emergency_contacts.${index}.relationship_id`}
                                        invalid={
                                          !!errors?.emergency_contacts?.[index]
                                            ?.relationship_id
                                        }
                                      />
                                      <FormFeedback invalid>
                                        {
                                          errors.emergency_contacts?.[index]
                                            ?.relationship_id?.message
                                        }
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="3" xs={12} md={5} sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>Phone Number</Label>
                                      <PhoneInputField
                                        control={control}
                                        name={`emergency_contacts.${index}.phone`}
                                        invalid={
                                          !!errors.emergency_contacts?.[index]
                                            ?.phone
                                        }
                                      />
                                      <FormFeedback invalid>
                                        {
                                          errors.emergency_contacts?.[index]
                                            ?.phone?.message
                                        }
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="3" xs={12} md={5} sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>Email</Label>
                                      <UFInput
                                        control={control}
                                        placeholder="Enter the email"
                                        name={`emergency_contacts.${index}.email`}
                                        invalid={
                                          !!errors?.emergency_contacts?.[index]
                                            ?.email
                                        }
                                      />
                                      <FormFeedback invalid>
                                        {
                                          errors.emergency_contacts?.[index]
                                            ?.email?.message
                                        }
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="3" xs={12} md={5} sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>Address</Label>
                                      <UFEditor
                                        control={control}
                                        placeholder="Enter the Current Address"
                                        rows="5"
                                        name={`emergency_contacts.${index}.address`}
                                        invalid={
                                          !!errors.mergency_contacts?.[index]
                                            ?.address
                                        }
                                        className={"pb-0"}
                                      />
                                      <FormFeedback invalid>
                                        {
                                          errors.emergency_contacts?.[index]
                                            ?.email?.address
                                        }
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>

                                  <Col
                                    lg="12"
                                    xs={12}
                                    md={2}
                                    sm={12}
                                    className="d-flex justify-content-end"
                                  >
                                    {emergencyFields.length == index + 1 ? (
                                      <Button
                                        color="secondary"
                                        className="addButton my-3 mt-0 ms-3"
                                        onClick={() =>
                                          emergencyAppend({
                                            name: "",
                                            relationship_id: null,
                                            phone: "",
                                            email: "",
                                            address: "",
                                          })
                                        }
                                      >
                                        Add Row
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    {emergencyFields.length > 1 ? (
                                      <Button
                                        color="primary"
                                        className="removeButton my-3 ms-3 mt-0 "
                                        onClick={() => emergencyRemove(index)}
                                      >
                                        Delete Row
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Row>
                              ))}
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                        <hr className="m-0" />
                        <AccordionItem>
                          <AccordionHeader targetId="9">
                            <h6> Medical Details</h6>
                          </AccordionHeader>
                          <AccordionBody accordionId="9">
                            <Row className="mb-4 mt-2">
                              {medicalFields.map((item, index) => (
                                <Row key={item.id}>
                                  <Col lg="3" xs={12} md={5} sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>Vaccine</Label>
                                      <Vaccine
                                        control={control}
                                        placeholder="Select"
                                        name={`medical_details.${index}.vaccine_id`}
                                        invalid={
                                          !!errors?.medical_details?.[index]
                                            ?.vaccine_id
                                        }
                                      />
                                      <FormFeedback invalid>
                                        {
                                          errors.medical_details?.[index]
                                            ?.vaccine_id?.message
                                        }
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="3" xs={12} md={5} sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>Vaccinated on</Label>
                                      <UFDate
                                        control={control}
                                        name={`medical_details.${index}.date`}
                                        invalid={
                                          !!errors?.medical_details?.[index]
                                            ?.date
                                        }
                                      />
                                      <FormFeedback invalid>
                                        {
                                          errors.medical_details?.[index]?.date
                                            ?.message
                                        }
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="3" xs={12} md={5} sm={12}>
                                    <FormGroup className="mb-4">
                                      <Label>Booster</Label>
                                      <BoosterList
                                        control={control}
                                        name={`medical_details.${index}.booster_id`}
                                        invalid={
                                          !!errors.medical_details?.[index]
                                            ?.booster_id
                                        }
                                      />
                                      <FormFeedback invalid>
                                        {
                                          errors.medical_details?.[index]
                                            ?.booster_id?.message
                                        }
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>

                                  <Col
                                    lg="12"
                                    xs={12}
                                    md={2}
                                    sm={12}
                                    className="d-flex justify-content-end"
                                  >
                                    {medicalFields.length == index + 1 ? (
                                      <Button
                                        color="secondary"
                                        className="addButton my-3 mt-0 ms-3"
                                        onClick={() =>
                                          medicalAppend({
                                            vaccine_id: null,
                                            booster_id: null,
                                            date: "",
                                          })
                                        }
                                      >
                                        Add Row
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    {medicalFields.length > 1 ? (
                                      <Button
                                        color="primary"
                                        className="removeButton my-3 ms-3 mt-0 "
                                        onClick={() => medicalRemove(index)}
                                      >
                                        Delete Row
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Row>
                              ))}
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                        <hr className="m-0" />
                        <AccordionItem>
                          <AccordionHeader targetId="10">
                            <h6> Benefits</h6>
                          </AccordionHeader>
                          <AccordionBody accordionId="10">
                            <Row className="mb-4 mt-2">
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Benefits</Label>
                                  <BenefitsLi
                                    control={control}
                                    placeholder="select"
                                    name={"benefits"}
                                    invalid={!!errors.benefits}
                                    target={"body"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.benefits?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                        <hr className="m-0" />
                        <AccordionItem>
                          <AccordionHeader targetId="11">
                            <h6> Entitlement</h6>
                          </AccordionHeader>
                          <AccordionBody accordionId="11">
                            <Row className="mb-4 mt-2">
                              <Col sm={3}>
                                <FormGroup className="mb-4">
                                  <Label>Entitlement</Label>
                                  <EntitlementLi
                                    control={control}
                                    placeholder="select"
                                    name={"entitlement"}
                                    invalid={!!errors.entitlement}
                                    target={"body"}
                                  />
                                  <FormFeedback invalid>
                                    {errors.entitlement?.message}
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AccordionBody>
                        </AccordionItem>
                        <hr className="m-0" />
                      </UncontrolledAccordion>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </Form>
          </FormProvider>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditEmployee
